import { useSelector } from 'react-redux'
import theme from '../../../theme'
import { Container, Grid, Typography } from '@mui/material'
import { useDispatch } from 'react-redux';
import useQuery from '../../hooks/useQuery';
import { useEffect } from 'react';
import { getAllVaccines, getVaccineById } from '../../../redux/features/vaccine/vaccineSlice';
import { AppDispatch } from '../../../redux/reducer';

type Props = {

}

function VaccineDetails({ }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const query = useQuery();
    const vaccine = useSelector((state: any) => state.vaccine.vaccine)
    let vaccineId = query.get("vaccineId");

    const fillData = async () => {
        await dispatch(getAllVaccines());
        if (vaccineId) {
            await dispatch(getVaccineById({ id: vaccineId }));
        }
    }

    useEffect(() => {
        fillData();
    }, [vaccineId])

    return (

        <Container sx={{ height: "100%", }}   >
            <Grid container borderRadius={theme.shape.borderRadius} display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} height={"100%"}
                sx={{
                    backgroundColor: theme.palette.common.white,
                    height: '100%',
                }}
                fontFamily={"Inter"}
                pl={2}
            >
                <Grid item md={6} xs={12}
                    color={'text.primary'}
                    height={"50%"}
                    textAlign={"left"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-end"}

                >
                    <Typography variant='body2' >
                        Vaccination
                    </Typography>
                </Grid>
                <Grid item md={1} xs={12}
                    color={'text.primary'}
                    height={"50%"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-end"}

                >
                    <Typography variant='body2' >
                        LoT
                    </Typography>
                </Grid>
                <Grid item md={3} xs={12}
                    color={'text.primary'}
                    height={"50%"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-end"}
                    pl={2}
                >
                    <Typography variant='body2' >
                        Expiry Date
                    </Typography>
                </Grid>
                <Grid item md={2} xs={12}
                    color={'text.primary'}
                    height={"50%"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-end"}
                >
                    <Typography variant='body2' >
                        Quantity
                    </Typography>
                </Grid>

                {/* ============= */}
                <Grid item md={6} xs={12}
                    color={'text.primary'}
                    height={"50%"}
                >
                    <Typography variant='h6' >
                        {vaccine?.vaccine}
                    </Typography>
                </Grid>
                <Grid item md={1} xs={12}
                    color={'text.primary'}
                    height={"50%"}
                >
                    <Typography variant='h6' >
                        {vaccine?.LoT}
                    </Typography>
                </Grid>
                <Grid item md={3} xs={12}
                    color={'text.primary'}
                    height={"50%"}
                    pl={2}
                >
                    <Typography variant='h6' >
                        {vaccine?.expiry}
                    </Typography>
                </Grid>
                <Grid item md={2} xs={12}
                    color={'text.primary'}
                    height={"50%"}
                >
                    <Typography variant='h6' >
                        {vaccine?.qty}
                    </Typography>
                </Grid>
            </Grid>
        </Container >
    )
}

export default VaccineDetails