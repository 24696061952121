import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MobileFacilityCard from '../../shared/components/MobileFacilityCard/MobileFacilityCard';
import VaccineDates from '../../shared/components/VaccineDates/VaccineDates';
import MobileVaccineSpotlight from '../../shared/components/VaccineSpotlight/MobileVaccineSpotlight';
import theme from '../../theme';


const styles = {
    cardTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        // border: 1,
        width: "97%",
    },
    cardContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "90%",
    },
    formHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    vaccineContainer: {
        backgroundColor: '#EDEDED66',
        paddingY: '1rem',
        borderRadius: theme.shape.borderRadius,
        mb: 2,
        [theme.breakpoints.down('md')]: {
            width: "100%",
            backgroundColor: theme.palette.common.white,
        },
        // border: 1
    },
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mb: 2
    },
    bottomPaper: {
        position: 'fixed',
        bottom: 0, left: 0,
        right: 0, height: 70,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingX: 2,
        // backgroundColor: theme.palette.background.default,
        backgroundColor: "#F2F1FA",
        color: theme.palette.primary.main
    },
}

type Props = {
    patientData:
    {
        label: string,
        value: string
    }[]
    ;
    vaccineDatesList:
    {
        title: string,
        dates: moment.Moment[]
    }[];
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

function MobilePatientDetails({ patientData, vaccineDatesList, setShowModal }: Props) {
    const vaccineDates = useSelector((state: any) => state.vaccine.vaccineDates);

    const [expanded, setExpanded] = useState(false);
    const vaccine = useSelector((state: any) => state.vaccine.vaccine)
    const facility = useSelector((state: any) => state.facility.facility)
    const navigate = useNavigate();

    return (
        <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
            <MobileFacilityCard />
            <MobileVaccineSpotlight />

            <Container sx={{ mt: 1, mb: "1rem", backgroundColor: theme.palette.common.white, py: 2 }}>
                <Box sx={styles.formHeader} >
                    <Typography variant='body2' sx={{ fontSize: "1rem", fontWeight: 500, color: theme.palette.text.primary }} >Patient Record</Typography>
                </Box>
                <Grid maxWidth={"xl"} container   >
                    <Grid container rowGap={2} mt={2}  >
                        {patientData.map((item, index) => (
                            index >= 8 ?
                                (expanded ?
                                    <Grid key={index} item xs={6} sm={3} >
                                        <Typography variant='body2' fontSize={"0.7rem"} color={theme.palette.text.secondary}>{item.label}</Typography>
                                        <Typography variant='body2' fontSize={"0.9rem"} fontWeight={theme.typography.fontWeightMedium}>{item.value}</Typography>
                                    </Grid> : null)
                                :
                                <Grid key={index} item xs={6} sm={3} >
                                    <Typography variant='body2' fontSize={"0.7rem"} color={theme.palette.text.secondary}>{item.label}</Typography>
                                    <Typography variant='body2' fontSize={"0.9rem"} fontWeight={theme.typography.fontWeightMedium}>{item.value}</Typography>
                                </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Box onClick={() => setExpanded(!expanded)} sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }} >
                    {expanded ?
                        <ExpandCircleDownOutlinedIcon sx={{ transform: "rotate(180deg)" }} fontSize='small' color="primary" />
                        :
                        <ExpandCircleDownOutlinedIcon fontSize='small' color="primary" />}
                    <Typography variant='subtitle2' ml={1} color={theme.palette.primary.main} >{expanded ? "View less Patient info" : "View more Patient info"}</Typography>
                </Box>
            </Container>
            <Container sx={{ mt: 1, mb: "1rem", backgroundColor: theme.palette.common.white, pt: 2 }}>
                <Box sx={styles.formHeader} >
                    <Typography variant='body2' sx={{ fontSize: "1rem", fontWeight: 500, color: theme.palette.text.primary }} >Vaccine Info</Typography>
                    {/* <Typography variant='body2' sx={{ fontSize: "0.8rem", fontWeight: 500, color: theme.palette.primary.main }} >Download</Typography> */}
                </Box>
                <Box sx={styles.vaccineContainer}>
                    <VaccineDates key={vaccine?.vaccine} title={vaccine.vaccine} dates={vaccineDates} setShowModal={setShowModal} />
                </Box>
            </Container >
            <Container sx={{ mt: 1, mb: "4rem", backgroundColor: theme.palette.common.white, pt: 2 }}>
                <Box sx={styles.formHeader} >
                    <Typography variant='body2' sx={{ fontSize: "1rem", fontWeight: 500, color: theme.palette.text.primary }} >Vaccine History</Typography>
                    {/* <Typography variant='body2' sx={{ fontSize: "0.8rem", fontWeight: 500, color: theme.palette.primary.main }} >Download</Typography> */}
                </Box>
                <Box sx={styles.vaccineContainer}>

                    {vaccineDatesList?.map((item, index) => (
                        <VaccineDates key={item.title + index} title={item.title} dates={item.dates} setShowModal={setShowModal} hideButtons={true} />
                    ))}
                </Box>
            </Container >
            <Paper
                onClick={() => navigate("/patient-search?facilityId=" + facility.id + "&vaccineId=" + vaccine.id)}
                sx={styles.bottomPaper}
                elevation={3}
            >
                <SearchIcon sx={{ fontSize: "1.5rem" }} />
                <Typography
                    variant='body2'
                    fontWeight={theme.typography.fontWeightMedium}
                    color={theme.palette.primary.main}
                >
                    Search
                </Typography>
            </Paper >

        </Box >
    )
}

export default MobilePatientDetails