import { Box, Container, Grid, Typography } from '@mui/material';
import { images } from '../../../assets/images';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import theme from '../../../theme';
import SearchFeature from './SearchFeature';
import VaccineDetails from './VaccineDetails';

const styles = {
    container: {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        backgroundImage: `
            linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
            url("${images.vaxBg1}")
        `,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'contain',
        [theme.breakpoints.down('md')]: {
            display: "none"
        },
        mb: 3,
        minWidth: "100%",
    }
}

type Props = {
    // isSearch?: boolean,
    // vaccineDetails?: {
    //     id: string,
    //     vaccine: string,
    //     LoT: number,
    //     expiry: string,
    //     qty: number
    // },
    // title?: string
    handleSearch?: (value: string) => void
}

// type Props = {
//     isSearch?: true;
//     handleSearch: (value: string) => void;
// } | {
//     isSearch?: false;
//     vaccineDetails?: {
//         id: string;
//         vaccine: string;
//         LoT: number;
//         expiry: string;
//         qty: number;
//     };
//     title?: string;
// };


function VaccineSpotlight({ handleSearch }: Props) {
    const location = useLocation();
    const [_, setPage] = React.useState('');
    const [isSearch, setIsSearch] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [placeholder, setPlaceholder] = React.useState('');
    const [showVaccineDetails, setShowVaccineDetails] = React.useState(false);

    useEffect(() => {

        switch (location.pathname) {
            case "/":
                setIsSearch(true);
                setTitle("Choose your categories");
                setPlaceholder("Search");
                setPage("Home");
                break;
            case "/facilities":
                setIsSearch(true);
                setTitle("Choose your Facility for this Session");
                setPlaceholder("Search Facility");
                setPage("Facility");
                break;
            case "/vaccine-list":
                setIsSearch(false);
                setTitle("Choose Vaccination");
                setPage("Vaccination");
                break;
            case "/patient-search":
                setIsSearch(false);
                setShowVaccineDetails(true);
                setPage("Vaccination");
                break;
            case "/patient-details":
                setIsSearch(false);
                setShowVaccineDetails(true);
                setPage("Patient Result");
                break;
            case "/add-patient":
                setIsSearch(false);
                setShowVaccineDetails(true);
                setPage("Add Patient");
                break;
            default:
                setPage("Home");
                break;
        }
    }, [location.pathname])


    return (
        <Container sx={styles.container}>
            <Grid container
                sx={{
                    height: '16vh',

                }}
            >
                <Grid
                    item
                    xs={12}
                    my={"auto"}
                    height={'80%'}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignContent={'center'}
                >
                    {
                        isSearch
                            ?
                            <SearchFeature title={title} handleSearch={handleSearch} placeholder={placeholder} />
                            : showVaccineDetails
                                ? <VaccineDetails />
                                : <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} >
                                    <Typography sx={{ color: '#FFF', fontSize: '1.5rem', fontWeight: 700 }}>
                                        {title}
                                    </Typography>
                                </Box>
                    }

                    {/* <VaccineDetails /> */}

                </Grid>
            </Grid>

        </Container>
    )
}

export default VaccineSpotlight