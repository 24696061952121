// facilitySlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllFacilitiesAPI } from '../../../services/facilityService';
import { FacilityType } from '../../../shared/types';



interface FacilityState {
    facility: {
        id: string | null;
        name: string | null;
    };
    facilities: any[];
}


export const getAllFacilities = createAsyncThunk<FacilityType[], void>(
    'facility/getAllFacilities',
    async (_, thunkAPI) => {
        try {
            const response = await getAllFacilitiesAPI();
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

const initialState: FacilityState = {
    facility: {
        id: null,
        name: null
    },
    facilities: [
        // {
        //     id: "1",
        //     name: "Ministry of Public Health (1001)"
        // },
        // {
        //     id: "2",
        //     name: "American Hospital Doha (1050)"
        // },
        // {
        //     id: "3",
        //     name: "Qatar Airways"
        // },
        // {
        //     id: "4",
        //     name: "Hamad Medical Corporation"
        // }
    ]
};

const facilitySlice = createSlice({
    name: 'facility',
    initialState,
    reducers: {
        getFacilitieById(state, action: PayloadAction<{ id: string; }>) {

            state.facility = state.facilities.find((facility) => facility.id === action.payload.id);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllFacilities.fulfilled, (state, action) => {
                state.facilities = action.payload;
            })
    }
});

export const { getFacilitieById } = facilitySlice.actions;
export default facilitySlice.reducer;
