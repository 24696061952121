import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Box, Container, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../theme';
const styles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "4rem",
        backgroundColor: theme.palette.secondary.main,
        // border: 1,
        width: "100vw"
    },
    leftBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        // border: 1
    },
    rightBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    avatar: {
        width: "1.8rem",
        height: "1.8rem",
    }
}


type Props = {
    title: string
}

function MobileAppHeader({ title }: Props) {
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const [page, setPage] = React.useState('');
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };


    useEffect(() => {
        const hasQueryParams = location.search !== '';


        switch (location.pathname) {
            case "/":
                setPage("Home");
                break;
            case "/facilities":
                setPage("Facility");
                break;
            case "/vaccine-list":
                if (!hasQueryParams) {
                    navigate('/');
                    break;
                }
                setPage("Vaccination");
                break;
            case "/patient-search":
                if (!hasQueryParams) {
                    navigate('/');
                    break;
                }
                setPage("Vaccination");
                break;
            case "/patient-details":
                if (!hasQueryParams) {
                    navigate('/');
                    break;
                }
                setPage("Patient Result");
                break;
            case "/add-patient":
                if (!hasQueryParams) {
                    navigate('/');
                    break;
                }
                setPage("Add Patient");
                break;
            default:
                setPage("Home");
                break;
        }

    }, [location.pathname])




    const DrawerList = (
        <Box sx={{ width: 300 }} role="presentation" onClick={toggleDrawer(false)}>
            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'flex-start', alignItems: "flex-start", paddingLeft: "1rem", mt: 1 }} >
                <IconButton sx={{ pl: 0 }}>
                    <Avatar alt="Shubham Gulik" src="/static/images/avatar/2.jpg" />
                </IconButton>
                <Box >
                    <Typography sx={{ fontWeight: 700, mb: 1 }} variant="body1"> Shubham Gulik</Typography>
                    <Typography sx={{ fontWeight: 400, color: theme.palette.text.secondary }} variant="body2"> Ministry of Public Health</Typography>
                </Box>
            </Box>
            <List>
                {['Logout',].map((text, index) => (

                    <ListItem key={text} disablePadding >
                        <ListItemButton onClick={() => navigate("/login")} >
                            <ListItemIcon>
                                <LogoutIcon color='primary' fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText color='text.primary' primary={text} />
                        </ListItemButton>
                    </ListItem>

                ))}
            </List>

        </Box >
    );

    return (
        <>
            <Container maxWidth="xl" sx={styles.container}>
                <Box sx={styles.leftBox} >
                    {page === "Home"
                        ?
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            sx={{ padding: 0, }}
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>

                        :
                        <ArrowBackIosNewIcon
                            sx={{ cursor: 'pointer' }}
                            fontSize='inherit'
                            onClick={() => window.history.back()} />
                    }

                    <Typography variant='h5' color={theme.palette.text.primary} ml={1}> {page} </Typography>


                </Box>
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
                <Box sx={[styles.rightBox, { display: { sm: 'flex', xs: 'flex', md: 'none' } }]} >
                    {page !== "Home"
                        &&
                        <HomeOutlinedIcon sx={{
                            cursor: 'pointer', width: "2rem",
                            height: "2rem",
                        }} color='primary' fontSize='medium' onClick={() => navigate('/')} />}

                    <IconButton sx={{ p: 0, ml: 2, border: 1 }}>
                        <Avatar sx={styles.avatar} alt="Shubham Gulik" src="/static/images/avatar/2.jpg" />
                    </IconButton>
                </Box>
                {/* <Box sx={{ flexGrow: 0, display: { sm: 'flex', xs: 'none', md: 'flex' } }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        <IconButton sx={{ p: 0 }}>
                            <Avatar alt="Shubham Gulik" src="/static/images/avatar/2.jpg" />
                        </IconButton>
                        <Box sx={{ ml: 0, }}>
                            <Typography sx={{ ml: 1, fontWeight: 700, }} variant="body1"> Shubham Gulik</Typography>
                            <Typography sx={{ ml: 1, fontWeight: 400, }} variant="body2"> Ministry of Public Health</Typography>
                        </Box>
                        <Box sx={{ justifyContent: 'center', alignItems: 'center' }} ><LogoutIcon color='primary' sx={{ ml: 2, cursor: 'pointer' }} /></Box>
                    </Box>
                </Box> */}

            </Container >
            {/* <Outlet /> */}
        </>
    )
}

export default MobileAppHeader