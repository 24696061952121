
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../shared/components/AlertDialog/AlertDialog';
import VaccineSpotlight from '../../shared/components/VaccineSpotlight/VaccineSpotlight';
import { Constants } from '../../shared/constants';
import DesktopPatientSearch from './DesktopPatientSearch';
import MobilePatientSearch from './MobilePatientSearch';
import { useSelector } from 'react-redux'
import { Box } from '@mui/material';
import StickyFooter from '../../shared/components/StickyFooter/StickyFooter';

type Props = {
    isPhoneScreen?: boolean
}

interface FormValues {
    qid: string;
    hcNo: string;
    passport: string;
    visa: string;
    phoneNo: string;
    state: string;
}

// const usePatientSearchInput = (initialState: FormValues) => {

//     const [values, setValues] = useState<FormValues>(initialState);
//     const handleChange = (prop: keyof typeof values) => (event: React.ChangeEvent<HTMLInputElement>) => {
//         setValues({ ...values, [prop]: event.target.value });
//     };

//     return [values, handleChange];
// }

const initialFormData = {
    qid: '',
    hcNo: '',
    passport: '',
    visa: '',
    phoneNo: '',
    state: '',
};

function PatientSearch({ isPhoneScreen }: Props) {
    const navigate = useNavigate();
    const [showModal, setShowModal] = React.useState(false);
    const vaccine = useSelector((state: any) => state.vaccine.vaccine)
    const facility = useSelector((state: any) => state.facility.facility)
    const [formData, setFormData] = useState(initialFormData);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const clearSearch = () => {
        setFormData(initialFormData);
    }

    // const [values, handleChange] = usePatientSearchInput({
    //     qid: '',
    //     hcNo: '',
    //     passport: '',
    //     visa: '',
    //     phoneNo: '',
    //     state: '',
    // });
    const handleSearch = (searchQuery: string) => {
        setShowModal(true);
        navigate("/patient-details?facilityId=" + facility?.id + "&vaccineId=" + vaccine?.id);
    }
    const handleAction1 = () => {
        setShowModal(false);
    }
    const handleAction2 = () => {
        // navigate("");
        // setShowModal(false);
    }

    const handleCloseAction = () => {
        setShowModal(false);
    }


    return (
        <>
            {/* {open && <AlertDialog title={Constants.PATIENT_NOT_FOUND} subTitle={Constants.DO_YOU_WANT_TO_ADD} visible={open} icon={Constants.SEARCH_ICON} action1={"Add"} action2={"Close"} handleCloseAction={() => setOpen(false)} />} */}

            {showModal &&
                <AlertDialog
                    title={Constants.PATIENT_NOT_FOUND}
                    // subTitle={Constants.DO_YOU_WANT_TO_ADD}
                    visible={showModal}
                    icon={Constants.SEARCH_ICON}
                    // action1={"Add"}
                    // action2={"Close"}
                    handleAction1={handleAction1}
                    handleAction2={handleAction2}
                    handleCloseAction={handleCloseAction}
                />
            }
            {isPhoneScreen
                ?
                <MobilePatientSearch
                    handleSearch={handleSearch}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    clearSearch={clearSearch}
                />
                :
                <Box style={{ height: '100%', display: 'flex', flexDirection: 'column', border: 1 }}>
                    <VaccineSpotlight />

                    <DesktopPatientSearch
                        handleSearch={handleSearch}
                        setShowModal={setShowModal}
                        formData={formData}
                        handleInputChange={handleInputChange}
                        clearSearch={clearSearch}
                    />
                    <StickyFooter />

                </Box>
            }
        </ >
    )
}

export default PatientSearch