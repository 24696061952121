import React, { ReactNode, createContext, useContext, useState } from 'react';
import AlertMessage from './AlertMessage';

interface AlertContextType {
    showAlert: (message: string, type: 'success' | 'error' | 'warning') => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [alertMessage, setAlertMessage] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);

    const showAlert = (message: string, type: 'success' | 'error' | 'warning') => {
        setAlertMessage({ message, type });
        setTimeout(() => {
            setAlertMessage(null);
        }, 3000); // Auto-hide after 3 seconds
    };

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}
            {alertMessage && <AlertMessage message={alertMessage.message} type={alertMessage.type} />}
        </AlertContext.Provider>
    );
};

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};
