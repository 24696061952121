import { Box, Typography } from '@mui/material'
import theme from '../../../theme'

type Props = { title: String, titleIcon?: JSX.Element }

const styles = {
    container: {
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    }
}

function SectionTitle({ title, titleIcon }: Props) {
    return (
        <Box sx={styles.container} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mb={2} >
            {titleIcon ? titleIcon : null}
            <Typography variant='h4'  >
                &nbsp;{title}
            </Typography>
        </Box>
    )
}

export default SectionTitle