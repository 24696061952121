import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Constants } from '../../constants';
import theme from '../../../theme';

function Copyright() {
    return (
        <Typography variant="body2" color="text.primary" textAlign={'center'}>
            {'Copyright © '}
            {new Date().getFullYear()}
            <Link style={{ textDecoration: 'none' }} color="inherit" href="/">
                &nbsp; {Constants.COPYRIGHT_TEXT}
            </Link>{' All rights reserved.'}

        </Typography>
    );
}

const styles = {
    footer: {
        display: 'flex',
        flexDirection: 'column',
        border: 1,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    }
}

export default function StickyFooter() {
    return (
        // <Box
        //     sx={styles.footer}
        // >
        //     <CssBaseline />

        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
            }}
        >
            <Container maxWidth="sm">
                <Copyright />
            </Container>
        </Box>
        // </Box>
    );
}