import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addVaccineDate } from '../../../redux/features/vaccine/vaccineSlice';
import { AppDispatch } from '../../../redux/reducer';
import theme from '../../../theme';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#EDEDED',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: '0.9rem'
}));

// const OpenPickerIcon = styled(MoreTimeIcon)({
//     backgroundColor: "red"
// });

const styles = {

    datesContainer: {
        // spacing: 2,
        // [theme.breakpoints.down('xs')]: {
        //     spacing: 1
        // }
    },
    singleDate: {
        // fontSize: '0.8rem',
        boxShadow: 0,
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.up('xs')]: {
            fontSize: '0.7rem',
            // paddingLeft: '0.3rem'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.8rem'
        },
        // border: 1
        // padding: 0
    }
}




type Props = {
    title: string;
    dates: moment.Moment[];
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    hideButtons?: boolean;
}

const defaultDates: moment.Moment[] = [
    moment("2024-03-05"),
    moment("2024-03-10"),
    moment("2024-03-15"),
    moment("2024-03-15"),
]


function VaccineDates({ title, dates, setShowModal, hideButtons }: Props) {
    const [showInputBox, setShowInputBox] = React.useState(false);
    const [date, setDate] = React.useState<moment.Moment>(moment());
    const dispatch: AppDispatch = useDispatch();

    const momentDates: moment.Moment[] = dates.map((dateString) => moment(dateString));

    const [dates1, setDates1] = useState<moment.Moment[]>(momentDates || []);

    const handleChange = (date: moment.Moment) => {
        console.log("date type", typeof date);

        setDate(date);
    }


    const saveDate = async () => {
        setShowInputBox(false);
        setDates1(prevDates => [...prevDates, date]);
        await dispatch(addVaccineDate(moment(date).format("YYYY-MM-DD")));
        setShowModal(true);
    }

    const handleShowInputBox = () => {
        setShowInputBox(true);
    }

    return (
        <Accordion disableGutters square sx={{ mb: 2, border: 2, borderRadius: theme.shape.borderRadius, borderColor: "#EDEDED", boxShadow: 0 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon color='primary' />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ color: theme.palette.common.black, fontWeight: 600, fontSize: '0.9rem', }}
            >
                <Box width={"80%"}>{title}</Box>
            </AccordionSummary>
            <AccordionDetails >
                <Grid container spacing={2} sx={styles.datesContainer}>
                    {dates1?.map((date, index) => (
                        <Grid key={index} item xs={4} sm={4} md={1.5}   >
                            <Item square sx={styles.singleDate}   >{date.format('DD/MM/YYYY')}</Item>
                        </Grid>
                    ))}
                    {showInputBox && <Grid item xs={12} sm={12} md={2} textAlign={"center"}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                sx={{
                                    width: '100%',
                                    // border: 1,
                                    // padding: 0,
                                    // fontSize: '2rem',
                                }}

                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        sx: {
                                            height: '100%',
                                            width: '100%',
                                            // border: 1,
                                            borderRadius: theme.shape.borderRadius,
                                            '& .MuiInputBase-root': {
                                                fontSize: '0.75rem',
                                                borderRadius: theme.shape.borderRadius,
                                            },

                                        },
                                    },

                                    openPickerIcon: {
                                        sx: {
                                            color: theme.palette.common.black,
                                            fontSize: '1.3rem',
                                            // border: 1
                                        }
                                    }
                                }}
                                value={date}
                                onChange={(newDate) => handleChange(moment(newDate))}
                                format='DD/MM/YYYY'
                                defaultValue={moment()}
                                name='Select Date'
                            />
                        </LocalizationProvider>
                    </Grid>}
                </Grid>
            </AccordionDetails>
            <AccordionActions>
                {
                    hideButtons ? <></> :
                        showInputBox ?
                            <>
                                <Button id='btn-save' onClick={() => saveDate()} size='medium' variant="contained"  >&nbsp; Save &nbsp;</Button>
                                <Button id='btn-cancel' onClick={() => setShowInputBox(false)} size='medium' variant="outlined"  >Cancel</Button>
                            </> :
                            <>
                                <Button onClick={handleShowInputBox} size='medium' variant="contained"  >Add Vaccination</Button>
                            </>
                }
            </AccordionActions>
        </Accordion>
    )
}

export default VaccineDates