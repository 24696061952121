import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import DesktopAppHeader from './DesktopAppHeader'
import MobileAppHeader from './MobileAppHeader'
import './AppHeader.css'
type Props = {
    isPhoneScreen: boolean
}



function AppHeader({ isPhoneScreen }: Props) {
    // const [user, setUser] = React.useState(null)

    // if (user === null) {
    //     return <Navigate to="/login" />
    // }
    return (
        <>
            {isPhoneScreen ?
                <>
                    <MobileAppHeader title='Vaccination' />
                    <Outlet />

                </>
                :
                <>
                    <DesktopAppHeader />
                    <Navbar />
                    <div className="content-wrapper">
                        <Outlet />
                    </div>
                </>
            }
        </>
    )
}

export default AppHeader