// vaccineSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllVaccinesAPI } from '../../../services/vaccineService';
import { VaccineType } from '../../../shared/types';
import moment from 'moment';

interface VaccineState {
    vaccine: {
        id: string | null;
        vaccine: string | null;
        LoT: number | null;
        expiry: string | null;
        qty: number | null;

    };
    vaccines: any[];
    filteredVaccines: any[];
    vaccineDates: string[];
}

export const getAllVaccines = createAsyncThunk<VaccineType[], void>('vaccine/getAllVaccines', async (_, thunkAPI) => {
    try {
        const response = await getAllVaccinesAPI();
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})


const defaultDates: string[] = [
    "2024-03-05",
    "2024-03-10",
]


const initialState: VaccineState = {
    vaccine: {
        id: null,
        vaccine: null,
        LoT: null,
        expiry: null,
        qty: null,
    },
    vaccineDates: defaultDates,
    vaccines: [
        // { id: "1", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.3 ml dose", LoT: 111, expiry: '12/12/2023', qty: 35 },
        // { id: "2", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.4 ml dose", LoT: 111, expiry: '12/12/2023', qty: 42 },
        // { id: "3", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.5 ml dose", LoT: 111, expiry: '12/12/2023', qty: 45 },
        // { id: "4", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.6 ml dose", LoT: 111, expiry: '12/12/2023', qty: 16 },
        // { id: "5", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.7 ml dose", LoT: 111, expiry: '12/12/2023', qty: null },
        // { id: "6", vaccine: "covaxin-19, mRNA, LNP-S, PF, 30 mcg/0.8 ml dose", LoT: 111, expiry: '12/12/2023', qty: 150 },
        // { id: "7", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.9 ml dose", LoT: 111, expiry: '12/12/2023', qty: 44 },
        // { id: "8", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.0 ml dose", LoT: 111, expiry: '12/12/2023', qty: 36 },
        // { id: "9", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.42 ml dose", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "50", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.7 ml dose", LoT: 111, expiry: '12/12/2023', qty: null },
        // { id: "60", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.8 ml dose", LoT: 111, expiry: '12/12/2023', qty: 150 },
        // { id: "70", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.9 ml dose", LoT: 111, expiry: '12/12/2023', qty: 44 },
        // { id: "80", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.0 ml dose", LoT: 111, expiry: '12/12/2023', qty: 36 },
        // { id: "90", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "500", vaccine: "covaxin-19, mRNA, LNP-S, PF, 30 mcg/0.7 ml dose", LoT: 111, expiry: '12/12/2023', qty: null },
        // { id: "600", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.8 ml dose", LoT: 111, expiry: '12/12/2023', qty: 150 },
        // { id: "700", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.9 ml dose", LoT: 111, expiry: '12/12/2023', qty: 44 },
        // { id: "800", vaccine: "covaxin-19, mRNA, LNP-S, PF, 30 mcg/0.0 ml dose", LoT: 111, expiry: '12/12/2023', qty: 36 },
        // { id: "900", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "9001", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "9002", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "9003", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
    ],
    filteredVaccines: [
        // { id: "1", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.3 ml dose", LoT: 111, expiry: '12/12/2023', qty: 35 },
        // { id: "2", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.4 ml dose", LoT: 111, expiry: '12/12/2023', qty: 42 },
        // { id: "3", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.5 ml dose", LoT: 111, expiry: '12/12/2023', qty: 45 },
        // { id: "4", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.6 ml dose", LoT: 111, expiry: '12/12/2023', qty: 16 },
        // { id: "5", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.7 ml dose", LoT: 111, expiry: '12/12/2023', qty: null },
        // { id: "6", vaccine: "covaxin-19, mRNA, LNP-S, PF, 30 mcg/0.8 ml dose", LoT: 111, expiry: '12/12/2023', qty: 150 },
        // { id: "7", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.9 ml dose", LoT: 111, expiry: '12/12/2023', qty: 44 },
        // { id: "8", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.0 ml dose", LoT: 111, expiry: '12/12/2023', qty: 36 },
        // { id: "9", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.42 ml dose", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "50", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.7 ml dose", LoT: 111, expiry: '12/12/2023', qty: null },
        // { id: "60", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.8 ml dose", LoT: 111, expiry: '12/12/2023', qty: 150 },
        // { id: "70", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.9 ml dose", LoT: 111, expiry: '12/12/2023', qty: 44 },
        // { id: "80", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.0 ml dose", LoT: 111, expiry: '12/12/2023', qty: 36 },
        // { id: "90", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "500", vaccine: "covaxin-19, mRNA, LNP-S, PF, 30 mcg/0.7 ml dose", LoT: 111, expiry: '12/12/2023', qty: null },
        // { id: "600", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.8 ml dose", LoT: 111, expiry: '12/12/2023', qty: 150 },
        // { id: "700", vaccine: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.9 ml dose", LoT: 111, expiry: '12/12/2023', qty: 44 },
        // { id: "800", vaccine: "covaxin-19, mRNA, LNP-S, PF, 30 mcg/0.0 ml dose", LoT: 111, expiry: '12/12/2023', qty: 36 },
        // { id: "900", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "9001", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "9002", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
        // { id: "9003", vaccine: "covaxin", LoT: 111, expiry: '12/12/2023', qty: 65 },
    ],

};

const vaccineSlice = createSlice({
    name: 'vaccine',
    initialState,
    reducers: {
        getVaccineById(state, action: PayloadAction<{ id: string }>) {
            state.vaccine = state.vaccines.find((vaccine) => vaccine.id === action.payload.id);
        },
        filterVaccines(state, action: PayloadAction<string>) {
            const searchQuery = action.payload;

            const filteredData = state.vaccines.filter(row =>
                Object.values(row).some(value =>
                    String(value).toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
            state.filteredVaccines = filteredData;
        },
        addVaccineDate(state, action: PayloadAction<string>) {
            state.vaccineDates.push(action.payload);
        },


    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllVaccines.fulfilled, (state, action) => {
                state.vaccines = action.payload;
                state.filteredVaccines = action.payload;
            })
    }
});

export const { getVaccineById, filterVaccines, addVaccineDate } = vaccineSlice.actions;
export default vaccineSlice.reducer;
