import { Container, Grid, Typography } from '@mui/material';
import MobileSearchBox from '../../shared/components/MobileSearchBox/MobileSearchBox';
import VaccineFacilityCard from '../../shared/components/VaccineFacilityCard/VaccineFacilityCard';
import theme from '../../theme';

type Props = {
    list: {
        name: string,
        icon: string
    }[]
    handleSearch: (searchQuery: string) => void
}



const styles = {
    container: {
        backgroundColor: theme.palette.secondary.main,
        height: "100vh",
        // border: 1
        // flex: 1
    }
}




function MobileCategories({ list, handleSearch }: Props) {

    return (
        <Container maxWidth="xl" sx={styles.container}>
            <Typography pt={1} variant='h5' mb={3} >Explore The Catrgories</Typography>
            <MobileSearchBox placeholder='Search' handleSearch={handleSearch} />
            <Grid container rowGap={3} mt={3}  >
                {list?.map((item, index) => (
                    <Grid key={item.name} item xs={12} md={5.5} sx={{ backgroundColor: theme.palette.common.white }} >
                        <VaccineFacilityCard title={item.name} to={"/facilities"} icon={item.icon} />
                    </Grid>
                ))}
            </Grid>
        </Container >
    )
}

export default MobileCategories