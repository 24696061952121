import { Box, Typography } from '@mui/material'
import { images } from '../../../assets/images'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import theme from '../../../theme';
import { categoryImages } from '../../../assets/images/categories';

type Props = {
    title: string,
    // handleFacilityClick?: (name: string) => void,
    to: string
    icon?: string
}

const styles = {
    container: {

    },
    imageContainer: {
        m: 1,
        bgcolor: 'transparent',
        height: "4rem",
        width: "4rem",
        backgroundColor: '#FEF5E3',
        borderRadius: theme.shape.borderRadius,
        display: 'flex', justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: "3rem",
            width: "3rem",
        },
    },
    cardTitle: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "1.2rem",
        ml: 1,
        flexGrow: 1,
        flex: 1,
        [theme.breakpoints.down('md')]: {
            fontSize: "0.9rem",
        }
    }
}
function VaccineFacilityCard({ title, to, icon }: Props) {
    const x = categoryImages.facilitySearch2x;


    return (
        <Link to={to} style={{ textDecoration: 'none', color: theme.palette.common.black }}>
            <Box
                sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                    border: "1px solid #F9B129",
                    borderRadius: theme.shape.borderRadius,
                    cursor: 'pointer',
                }}
            // height={"5rem"} 
            >
                <Box sx={styles.imageContainer}>
                    <img src={icon ? icon : images.facility2x} style={{ width: '60%', height: '60%', }} alt='logo' />
                </Box>
                <Typography sx={styles.cardTitle}>{title}</Typography>
                <ChevronRightIcon fontSize='large' sx={{ ml: 'auto', mr: 1 }} />
            </Box>
        </Link>
    )

}

export default VaccineFacilityCard