import { useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { LocalizationProvider } from '@mui/x-date-pickers';

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// import './App.css';
import AddPatient from './modules/AddPatient/AddPatient';
import Categories from './modules/Categories/Categories';
import Facility from './modules/Facility/Facility';
import PatientDetails from './modules/PatientDetails/PatientDetails';
import PatientSearch from './modules/PatientSearch/PatientSearch';
import VaccineList from './modules/VaccineList/VaccineList';
import { AlertProvider } from './modules/alerts/useAlert';
import Login from './modules/login/Login';
import store from './redux/store';
import ErrorBoundary from './shared/components/ErrorBoundary/ErrorBoundary';
import AppHeader from './shared/components/Header/AppHeader';
import theme from './theme';

import { updatePageMetadata } from './shared/utils/utils';

const App: React.FC = () => {
  const isPhoneScreen = useMediaQuery('(max-width: 900px)');
  useEffect(() => {
    updatePageMetadata('moph.ico', 'Ministry of Public Health');
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
        <Router >
          <AlertProvider>
            {/* <RouterProvider router={router} /> */}
            <Routes>
              <Route element={<AppHeader isPhoneScreen={isPhoneScreen} />}>
                <Route index element={<Categories isPhoneScreen={isPhoneScreen} />} />
                <Route path="/facilities" element={<Facility isPhoneScreen={isPhoneScreen} />} />
                <Route path="/vaccine-list" element={<VaccineList isPhoneScreen={isPhoneScreen} />} />
                <Route path="/patient-search" element={<PatientSearch isPhoneScreen={isPhoneScreen} />} />
                <Route path="/patient-details" element={<PatientDetails isPhoneScreen={isPhoneScreen} />} />
                <Route path="/add-patient" element={<AddPatient />} />
                <Route
                  path="*"
                  element={<Navigate to="/" />}
                />
              </Route>
              <Route path="/login" element={<Login />} />
            </Routes>
          </AlertProvider>
        </Router>
        {/* </LocalizationProvider> */}
      </Provider>
    </ThemeProvider>
  );
};

const DynamicBackgroundColor = ({ children }: { children: React.ReactNode }) => {
  // Check if screen size is below "md" breakpoint
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Define background color based on screen size
  const backgroundColor = isPhoneScreen ? '#F2F1FA' : '#FFF';

  return (
    <ThemeProvider theme={createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        background: {
          default: backgroundColor,
        },
      },
    })}>
      <div style={{ minHeight: '100vh', minWidth: '100vw', backgroundColor: backgroundColor }}>
        <ErrorBoundary fallback={<div>Something went wrong!</div>}>
          {children}
        </ErrorBoundary>
      </div>
    </ThemeProvider>
  );
};
export default App;
