import { Box, Button, Container, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';

import React from 'react';
import BackNavigate from '../../shared/components/BackNavigate/BackNavigate';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import StickyFooter from '../../shared/components/StickyFooter/StickyFooter';
import { Constants } from '../../shared/constants';
import theme from '../../theme';

const styles = {
    inputBox: { mt: 1, borderRadius: theme.shape.borderRadius, backgroundColor: '#FFF', }
}

function AddPatient() {
    const [_, setOpen] = React.useState(false);

    return (
        <>
            <Container sx={{ mb: 5 }} >
                {/* {open && <AlertDialog title={Constants.PATIENT_NOT_FOUND} subTitle={Constants.DO_YOU_WANT_TO_ADD} visible={open} icon={Constants.SEARCH_ICON} action1={"Add"} action2={"Close"} handleCloseAction={() => setOpen(false)} />} */}
                <BackNavigate title={Constants.BACK_TO_VACCINE_LIST} />
                <Box>
                    <SectionTitle title={"Add New Patient"} />
                    <Grid container sx={{ backgroundColor: '#EDEDED66' }} padding={2} pb={4} borderRadius={theme.shape.borderRadius} display={'flex'} justifyContent={'space-between'} alignItems={'center'} rowGap={2} mb={2}  >
                        <Grid item xs={12}  ><Typography variant="h6">Patient Details</Typography></Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5}  >
                            <InputLabel htmlFor="firsName" >
                                <Typography variant='body2'  >First Name</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="firsName"
                                autoComplete="firsName"
                                size='small'
                                // value={"28456785"}
                                type='text'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="lastName" >
                                <Typography variant='body2'  >Last Name</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="lastName"
                                autoComplete="lastName"
                                size='small'

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="birthDate" >
                                <Typography variant='body2'  >Birth Date</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="birthDate"
                                autoComplete="birthDate"
                                size='small'
                                type='date'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="qid" >
                                <Typography variant='body2'  >QID</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="qid"
                                autoComplete="qid"
                                size='small'

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="visa" >
                                <Typography variant='body2'  >Visa</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="visa"
                                autoComplete="visa"
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="passport" >
                                <Typography variant='body2'  >Passport</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="passport"
                                autoComplete="passport"
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="hcid" >
                                <Typography variant='body2'  >HC ID</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="hcid"
                                autoComplete="hcid"
                                size='small'

                            />
                        </Grid>

                    </Grid>
                    <Grid container sx={{ backgroundColor: '#EDEDED66' }} padding={2} pb={4} borderRadius={theme.shape.borderRadius} display={'flex'} justifyContent={'space-between'} alignItems={'center'} rowGap={2} mb={2}  >
                        <Grid item xs={12}  ><Typography variant="h6">Family and Address information</Typography></Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5}  >
                            <InputLabel htmlFor="guardianFirstName" >
                                <Typography variant='body2'  >Guardian First Name</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="guardianFirstName"
                                autoComplete="guardianFirstName"
                                size='small'
                                // value={"28456785"}
                                type='text'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="mothersMaidenName" >
                                <Typography variant='body2'  >Mothers Maiden's Name</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="mothersMaidenName"
                                autoComplete="mothersMaidenName"
                                size='small'

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="street" >
                                <Typography variant='body2'  >Street</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="street"
                                autoComplete="street"
                                size='small'

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="city" >
                                <Typography variant='body2'  >City</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="city"
                                autoComplete="city"
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="state" >
                                <Typography variant='body2'  >State</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="state"
                                autoComplete="state"
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3.5} >
                            <InputLabel htmlFor="postalCode" >
                                <Typography variant='body2'  >Postal Code</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                required
                                fullWidth
                                id="postalCode"
                                autoComplete="postalCode"
                                size='small'

                            />
                        </Grid>
                    </Grid>
                    <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                        <Button size='medium' onClick={() => { setOpen(true) }} sx={{ width: '23%' }} fullWidth variant="contained">Save Patient & Add Vaccination</Button>
                    </Box>
                </Box>
            </Container >
            <StickyFooter />
        </>
    )
}

export default AddPatient