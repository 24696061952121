import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
// import '../../../App.css';
import CloseIcon from '@mui/icons-material/Close';
import { Container, IconButton, Typography } from '@mui/material';
import theme from '../../../theme';
import './AlertDialog.css';

const styles = {
    container: {
        width: "100%",
        height: "50%",
        wrap: "wrap",
        // borderRadius: theme.shape.borderRadius,
        border: 1
    },
    flex: { display: "flex", justifyContent: "center", alignItems: "center", },
    dialogContainer: {
        borderRadius: theme.shape.borderRadius + 10,
        // border: 1
    },
    // button: { borderRadius: "4px", },
    buttonContainer: { width: "40%", alignSelf: "center", fontSize: "2rem" }
}
type Props = {
    title: String,
    subTitle?: String,
    visible: boolean,
    // icon: JSX.Element,
    icon: any,

    action1?: String,
    action2?: String,
    handleAction1: () => void,
    handleAction2: () => void,
    handleCloseAction: () => void
}
export default function AlertDialog({ title, subTitle, visible, icon, action1, action2, handleAction1, handleAction2, handleCloseAction }: Props) {

    const handleClose = () => {
        handleCloseAction();
        return;
    }

    return (
        <React.Fragment >
            <Container sx={styles.container}>
                <Dialog
                    open={visible}
                    onClose={handleClose}
                    sx={styles.dialogContainer}
                    fullWidth
                    maxWidth={"xs"}
                    PaperProps={{
                        style: {
                            borderRadius: theme.shape.borderRadius + 6, // Adjust the value as needed
                        },
                        square: false,
                    }}
                >
                    <IconButton
                        sx={{ position: 'absolute', top: 2, right: 2 }}
                        onClick={() => handleCloseAction()}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle sx={[styles.flex, { paddingBottom: "0rem" }]} id="alert-dialog-title">
                        {/* {icon} */}
                        <img src={icon} style={{ objectFit: "contain", width: "4rem", height: "4rem" }} alt='logo' />
                    </DialogTitle>

                    <DialogTitle variant='h2' sx={[styles.flex, { paddingTop: 0, paddingBottom: 0 }]} id="alert-dialog-title">
                        {/* <Typography variant='h2'  > */}
                        {title}
                        {/* </Typography> */}
                    </DialogTitle>
                    <DialogContent sx={[styles.flex,]}>
                        <DialogContentText fontSize={14} id="alert-dialog-description">
                            <Typography variant='body2' sx={{ md: { mb: 3 } }}  >{subTitle}</Typography>
                        </DialogContentText>
                    </DialogContent>
                    {action1 &&
                        <DialogActions sx={[styles.flex, styles.buttonContainer]} >
                            <Button fullWidth size='large' variant='contained' sx={{ fontSize: "1rem" }} autoFocus onClick={() => handleAction1()}  >
                                {action1}
                            </Button>
                        </DialogActions>}
                    {action2 &&
                        <DialogActions sx={[styles.flex, styles.buttonContainer, { width: "70%", mb: "1rem" }]} >
                            <Button fullWidth size='large' sx={{ fontSize: "1rem" }} onClick={() => handleAction2()} >
                                {action2}
                            </Button>
                        </DialogActions>
                    }
                </Dialog>
            </Container>
        </React.Fragment >
    );
}
