import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { images } from '../../../assets/images';
import { getAllFacilities, getFacilitieById } from '../../../redux/features/facility/facilitySlice';
import theme from '../../../theme';
import useQuery from '../../hooks/useQuery';
import { AppDispatch } from '../../../redux/reducer';

type Props = {
    title?: string,
    page?: string,
}

// function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
//     event.preventDefault();
//     console.info('You clicked a breadcrumb.');
// }

const styles = {
    container: {
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        minWidth: "100%",
    }
}

function Navbar({ }: Props) {
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const facilities = useSelector((state: any) => state.facility.facilities);
    const facility = useSelector((state: any) => state.facility.facility);
    const query = useQuery();
    const navigate = useNavigate();
    const [title, setTitle] = React.useState('');
    const [page, setPage] = React.useState('');

    let facilityId = query.get("facilityId");


    React.useEffect(() => {

        // Will call only when page is refreshed
        if (facilities.length === 0) {
            const getAll = async () => {
                await dispatch(getAllFacilities());
            }
            getAll();
        }
    }, [])

    React.useEffect(() => {
        initializeVars();
    }, [location.pathname, facility, facilities])

    const getFacilityAsync = async () => {
        if (facilityId) {
            await dispatch(getFacilitieById({ id: facilityId }));
        }
    }

    const initializeVars = async () => {
        await getFacilityAsync();
        switch (location.pathname) {
            case "/":
                setTitle("Home")
                setPage("");
                break;
            case "/facilities":
                setTitle("Facility")
                setPage("");
                break;
            case "/vaccine-list":
                setTitle(facility?.name);
                setPage("Vaccination");
                break;
            case "/patient-search":
                setTitle(facility?.name);
                setPage("Vaccination");
                break;
            case "/patient-details":
                setTitle(facility?.name);
                setPage("Patient Result");
                break;
            case "/add-patient":
                setTitle(facility?.name);
                setPage("Add Patient");
                break;
            default:
                setTitle(facility?.name);
                setPage("Home");
                break;
        }
    }



    const breadcrumbs = [
        <Link key={1} to={title == "Home" ? "/" : "/facilities"} style={{ textDecoration: 'none', color: "#F9B129" }} >
            {title}
        </Link>,
    ];
    if (page) {
        breadcrumbs.push(<Typography sx={{ fontSize: '1rem' }} key="3" color={theme.palette.common.white}>
            {page}
        </Typography>,)
    }

    return (
        <Container sx={styles.container} className='navbar' >
            <Container>
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: 'none', md: 'flex', sm: 'none' }, mr: 1 }} onClick={() => navigate("/")}>
                        <img style={{ width: 30, height: 30, }} src={`${images.home}`} alt='Nav Logo' />
                    </Box>
                    <Box sx={{ color: theme.palette.common.white, flexGrow: 1, display: { xs: 'none', md: 'flex', sm: "none" } }}>
                        <Stack color="info" spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon color='secondary' fontSize="medium" />}
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'none', sm: 'flex' }, mr: 1 }}>
                        <img style={{ width: 30, height: 30, }} src={`${images.home}`} alt='Nav Logo' />
                    </Box>
                    <Box sx={{ color: theme.palette.common.white, flexGrow: 1, display: { xs: 'none', md: 'none', sm: "flex" } }}>
                        <Stack color="info" spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon color='success' fontSize="medium" />}
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none', sm: "none" }, mr: 1 }}>
                        <img style={{ width: 30, height: 30, }} src={`${images.home}`} alt='Nav Logo' />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', sm: "none" } }}>
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon color='success' fontSize="medium" />}
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs[1]}
                            </Breadcrumbs>
                        </Stack>
                    </Box>
                </Toolbar>
            </Container>
        </Container>
    );
}
export default Navbar;
