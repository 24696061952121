import SuccessIcon from "./modalIcons/successIcon1x";

export const images = {
    login1: require("./auth/login1.png"),
    login2x: require("./auth/login2x.png"),
    login3x: require("./auth/login3x.png"),
    login4x: require("./auth/login4x.png"),
    logo: require("./auth/logo.png"),
    login75: require("./auth/login75.png"),
    login05: require("./auth/login05.png"),
    login512w: require("./auth/login512w.png"),
    login512h: require("./auth/login512h.png"),
    headerLogo: require("./header/headerLogo.png"),
    headerLogo1: require("./header/headerLogo1.png"),
    headerLogo15: require("./header/headerLogo15.png"),
    home: require("./header/home.png"),
    vaxBg1: require("./vaxSpotlight/vaxBg1.png"),
    vaxBg15: require("./vaxSpotlight/vaxBg15.png"),
    vaxBg75: require("./vaxSpotlight/vaxBg75.png"),
    facility1x: require("./facility/facility1x.png"),
    facility075x: require("./facility/facility075x.png"),
    facility15x: require("./facility/facility15x.png"),
    facility2x: require("./facility/facility2x.png"),
    successIcon1x: require("./modalIcons/successIcon1x.png"),
    successIcon2x: require("./modalIcons/successIcon2x.png"),
    successIcon15x: require("./modalIcons/successIcon15x.png"),
    successIcon75x: require("./modalIcons/successIcon75x.png"),
    allergyIcon2x: require("./modalIcons/allergyIcon2x.png"),
    searchIcon2x: require("./modalIcons/searchIcon2x.png"),
    noDataAvailable: require("./no_data.jpg"),
    eye2x: require("./auth/eye2x.png"),
    eye1x: require("./auth/eye1x.png"),
    eye75x: require("./auth/eye75x.png"),
}