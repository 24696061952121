import { Box, Button, Container, InputAdornment, OutlinedInput, TableCell, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridRow } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../redux/reducer';
import theme from '../../theme';
import "./VaccineList.css";
import StickyFooter from '../../shared/components/StickyFooter/StickyFooter';
import { images } from '../../assets/images';
import CustomNoRowsOverlay from '../../shared/components/CustomNoRowsOverlay/CustomNoRowsOverlay';


const SearchBox = styled(Box)(({ theme }) => ({
    marginLeft: 2,
    width: "100%",
    // height: "3ch",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

interface CustomRowProps {
    row: any;
}

const CustomRow: React.FC<CustomRowProps> = ({ row, ...props }) => {
    return <GridRow {...props} row={row} style={{ marginTop: '10px' }} />;
};

type Props = {
    columns: any;
    handleSearch: (searchQuery: string) => void;
}
function DesktopVaccineList({ columns, handleSearch }: Props) {
    const filteredVaccines = useSelector((state: RootState) => state.vaccine.filteredVaccines);
    const [searchQuery, setSearchQuery] = useState('');
    const isGridEmpty = filteredVaccines.length === 0;



    return (
        < >
            <Container sx={{ mb: 10, flexGrow: 1 }}  >
                <Box display={"flex"} width={"100%"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} marginX={"auto"} >
                    <Box display={"flex"} width={"40%"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                        <SearchBox >
                            <OutlinedInput
                                fullWidth
                                placeholder="Search Vaccination"
                                sx={{ backgroundColor: '#FFF', fontSize: '1rem', color: theme.palette.common.black, borderRadius: '4px' }}
                                id="outlined-basic"
                                size='small'
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyUpCapture={(e) => e.key === 'Enter' && handleSearch(searchQuery)}
                                startAdornment={<InputAdornment position="start"><SearchIcon sx={{ color: theme.palette.common.black }} /></InputAdornment>}
                            />
                        </SearchBox>
                        <Box marginLeft={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Button onClick={() => handleSearch(searchQuery)} sx={{ borderRadius: "4px", }} variant="contained">Search</Button>
                        </Box>
                    </Box>
                    <Typography variant='body2' sx={{ color: '#000', fontSize: '1.2rem', fontWeight: 600 }}>Total Results: {filteredVaccines.length}</Typography>
                </Box>
                <Box mt={5} sx={{ height: 500, width: '100%' }}  >
                    <DataGrid
                        rows={filteredVaccines}
                        columns={columns}
                        slots={{
                            row: CustomRow,
                            noRowsOverlay: () => <CustomNoRowsOverlay />,
                            noResultsOverlay: () => <CustomNoRowsOverlay />,
                        }}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: "#EDEDED",
                            }
                        }}
                    />
                </Box>
            </Container >
        </>
    )
}



export default DesktopVaccineList