import { Box, Button, InputAdornment, OutlinedInput } from '@mui/material';
import React from 'react'
import styled from 'styled-components';
import theme from '../../../theme';
import SearchIcon from '@mui/icons-material/Search';

const SearchBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1rem",
}));

type Props = {
    placeholder: string,
    handleSearch: (searchQuery: string) => void
}
function MobileSearchBox({ placeholder, handleSearch }: Props) {
    const [searchQuery, setSearchQuery] = React.useState('');
    return (
        <SearchBox >
            <OutlinedInput
                placeholder={placeholder}
                sx={{ backgroundColor: '#FFF', fontSize: '1rem', color: theme.palette.common.black, borderRadius: '4px', flex: 1 }}
                id="outlined-basic"
                size='small'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyUpCapture={(e) => e.key === 'Enter' && handleSearch(searchQuery)}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
            />
            <Button onClick={() => handleSearch(searchQuery)} sx={{ borderRadius: "4px", ml: 1 }} variant="contained" ><SearchIcon sx={{ color: theme.palette.common.white }} /></Button>
        </SearchBox>
    )
}

export default MobileSearchBox