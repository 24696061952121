import React from 'react';
import VaccineSpotlight from '../../shared/components/VaccineSpotlight/VaccineSpotlight';
import DesktopCategories from './DesktopCategories';
import MobileCategories from './MobileCategories';
import { categoryImages } from '../../assets/images/categories';
import StickyFooter from '../../shared/components/StickyFooter/StickyFooter';
import { Box } from '@mui/material';

type Props = {
    isPhoneScreen: boolean
}

interface ListItem {
    name: string;
    icon: string;
}

const list: ListItem[] = [
    {
        name: "Vaccine Administration",
        icon: categoryImages.vaccineAdmin2x
    },
    {
        name: "Facility Search",
        icon: categoryImages.facilitySearch2x
    },
    {
        name: "Patient Search",
        icon: categoryImages.patientSearch2x
    },
    {
        name: "Vaccine Search",
        icon: categoryImages.vaccineSearch2x
    }
];

function Categories({ isPhoneScreen }: Props) {

    const [filteredList, setFilteredList] = React.useState(list)
    const handleSearch = (searchQuery: string) => {
        const temp = list.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
        setFilteredList(temp);
    }



    return (
        <>
            {isPhoneScreen
                ?
                <MobileCategories list={filteredList} handleSearch={handleSearch} />
                :
                <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <VaccineSpotlight handleSearch={handleSearch} />
                    <DesktopCategories list={filteredList} />
                    <StickyFooter />
                </Box>
            }
        </>
    )
}

export default Categories