import { Box, Button, Container, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import BackNavigate from '../../shared/components/BackNavigate/BackNavigate';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import StickyFooter from '../../shared/components/StickyFooter/StickyFooter';
import { Constants } from '../../shared/constants';
import theme from '../../theme';

const styles = {
    inputBox: {
        mt: 1.3,
        borderRadius: "borderRadius",
        backgroundColor: '#FFF',
        fontWeight: 500,
        height: "3rem"
    },
    clearSearch: {
        height: "100%",
        borderRadius: "4px",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        }
    }
}

type Props = {
    handleSearch: (searchQuery: string) => void;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    formData: {
        qid: string;
        hcNo: string;
        passport: string;
        visa: string;
        phoneNo: string;
        state: string;
    };
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    clearSearch: () => void

}

function DesktopPatientSearch({ handleSearch, setShowModal, formData, handleInputChange, clearSearch }: Props) {

    return (
        < >
            <Container sx={{ mb: 5, flexGrow: 1 }} >
                {/* {open && <AlertDialog title={Constants.PATIENT_NOT_FOUND} subTitle={Constants.DO_YOU_WANT_TO_ADD} visible={open} icon={Constants.SEARCH_ICON} action1={"Add"} action2={"Close"} handleCloseAction={() => setOpen(false)} />} */}
                <BackNavigate title={Constants.BACK_TO_VACCINE_LIST} />
                <Box >
                    <SectionTitle title={"Advance Patient Search"} titleIcon={<SearchIcon fontSize='small' />} />
                    {/* <Box mt={5} width={'100%'} maxWidth={'100%'}  > */}
                    <Grid container sx={{ backgroundColor: '#EDEDED66' }} padding={2} borderRadius={theme.shape.borderRadius} display={'flex'} justifyContent={'space-between'} alignItems={'center'} rowGap={4}  >
                        <Grid item xs={12} sm={6} md={3.9} lg={3.9}  >
                            <InputLabel htmlFor="qid" >
                                <Typography variant='body2'  >QID</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                name='qid'
                                value={formData.qid}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                id="qid"
                                autoComplete="qid"
                                size='small'
                                type='text'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3.9} lg={3.9} >
                            <InputLabel htmlFor="hc" >
                                <Typography variant='body2'  >HC No</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                name='hcNo'
                                value={formData.hcNo}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                id="hc"
                                autoComplete="hc"
                                size='small'

                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3.9} lg={3.9} >
                            <InputLabel htmlFor="passport" >
                                <Typography variant='body2'  >Passport</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                name='passport'
                                value={formData.passport}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                id="passport"
                                autoComplete="email"
                                size='small'

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3.9} lg={3.9} >
                            <InputLabel htmlFor="visa" >
                                <Typography variant='body2'  >Visa</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                name='visa'
                                value={formData.visa}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                id="visa"
                                autoComplete="visa"
                                size='small'

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3.9} lg={3.9} >
                            <InputLabel htmlFor="phone" >
                                <Typography variant='body2'  >Phone Number</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                name='phoneNo'
                                value={formData.phoneNo}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                id="phone"
                                autoComplete="phone"
                                size='small'

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3.9} lg={3.9} >
                            <InputLabel htmlFor="state" >
                                <Typography variant='body2'  >State</Typography>
                            </InputLabel>
                            <OutlinedInput
                                sx={styles.inputBox}
                                name='state'
                                value={formData.state}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                id="state"
                                autoComplete="state"
                                size='small'

                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                            <Box width={"30%"} height={"3.12rem"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Button size='small' id='btn-search' onClick={() => { handleSearch("28456785") }} sx={{ borderRadius: "4px", mr: 3, height: "100%" }} fullWidth variant="contained">Search</Button>
                                <Button size='small' id='btn-clear' onClick={clearSearch} sx={styles.clearSearch} fullWidth variant="contained">Clear Search</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* </Box> */}
                </Box>
            </Container >
        </ >
    )
}

export default DesktopPatientSearch