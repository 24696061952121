import { Box, Typography } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import theme from '../../../theme';

type Props = { title: String };

const styles = {
    container: {
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    }
}

function BackNavigate({ title }: Props): JSX.Element {
    return (
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mb={4} sx={styles.container} >
            <ArrowBackIosNewIcon sx={{ cursor: 'pointer' }} color='primary' fontSize='inherit' onClick={() => window.history.back()} />
            <Typography sx={{ cursor: 'pointer' }} onClick={() => window.history.back()} variant='h6' color='primary'   >
                &nbsp;&nbsp;{title}
            </Typography>
        </Box>
    )
}

export default BackNavigate