import { Box, Container, Grid } from '@mui/material';

import StickyFooter from '../../shared/components/StickyFooter/StickyFooter';
import VaccineFacilityCard from '../../shared/components/VaccineFacilityCard/VaccineFacilityCard';
const styles = {
    facilityContainer: { mb: 10, borderRadius: 2, },
    gridStyles: { flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'center' }
}

type Props = {
    list: {
        id: string;
        name: string;
    }[]
    // handleFacilityClick: (name: string) => void
}

function DesktopFacility({ list }: Props) {
    return (
        <>
            <Container sx={{
                flexGrow: 1
            }}>
                <Box width={"100%"} sx={styles.facilityContainer}   >
                    <Grid container rowGap={3} sx={styles.gridStyles} >
                        {list?.map((facility: { id: string, name: string }) => (
                            <Grid key={facility.id} item xs={12} md={5.5}>
                                <VaccineFacilityCard key={facility.id} title={facility.name} to={`/vaccine-list?facilityId=${facility.id}`} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default DesktopFacility