import { Box, Button, Grid, Typography } from '@mui/material'
import theme from '../../../theme'


const styles = {
    mobileVaccineCard: {
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        // alignItems: "flex-start",
        padding: "1rem",
        // border: 1,
        borderRadius: theme.shape.borderRadius
    },
    cardTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flex: 1,
        mb: 2
    },
    cardContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 3
    }

}

type Props = {
    id: string
    vaccine: string
    LoT: number
    expiry: string
    qty: number
    handleVaccineClick: (id: string) => void
}

function MobileVaccineCard({ id, vaccine, LoT, expiry, qty, handleVaccineClick }: Props) {

    return (
        <Grid item xs={12} sm={5.5} md={5.5} sx={styles.mobileVaccineCard}>
            <Box sx={styles.cardTitle} >
                <Typography variant='body2' color={theme.palette.text.secondary}  >Vaccination</Typography>
                <Typography variant='body2' fontWeight={theme.typography.fontWeightMedium} >{vaccine}</Typography>
            </Box>
            <Box sx={styles.cardContent} >
                <Box>
                    <Typography variant='body2' color={theme.palette.text.secondary}  >LoT</Typography>
                    <Typography variant='body2' fontWeight={theme.typography.fontWeightMedium} >{LoT}</Typography>
                </Box>
                <Box>
                    <Typography variant='body2' color={theme.palette.text.secondary}  >Expiry Date</Typography>
                    <Typography variant='body2' fontWeight={theme.typography.fontWeightMedium} >{expiry}</Typography>
                </Box>
                <Box>
                    <Typography variant='body2' color={theme.palette.text.secondary}  >Quantity</Typography>
                    <Typography variant='body2' fontWeight={theme.typography.fontWeightMedium} >{qty}</Typography>
                </Box>
            </Box>
            <Button id={id} fullWidth size='large' variant='contained' onClick={() => { handleVaccineClick(id) }} >Select</Button>
        </Grid>
    )
}

export default MobileVaccineCard