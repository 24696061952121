import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { images } from '../../../assets/images';
import { useNavigate } from 'react-router-dom';
function DesktopAppHeader() {
    const navigate = useNavigate();

    // const toggleDrawer = (newOpen: boolean) => () => {
    //     setOpen(newOpen);
    // };

    // const DrawerList = (
    //     <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
    //         <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'flex-start', alignItems: "flex-start", paddingLeft: "1rem", mt: 1 }} >
    //             <IconButton sx={{ pl: 0 }}>
    //                 <Avatar alt="Shubham Gulik" src="/static/images/avatar/2.jpg" />
    //             </IconButton>
    //             <Box >
    //                 <Typography sx={{ fontWeight: 700, mb: 1 }} variant="body1"> Shubham Gulik</Typography>
    //                 <Typography sx={{ fontWeight: 400, color: theme.palette.text.secondary }} variant="body2"> Ministry of Public Health</Typography>
    //             </Box>
    //         </Box>
    //         <List>
    //             {['Home', 'Logout',].map((text, index) => (
    //                 <ListItem key={text} disablePadding >
    //                     <ListItemButton>
    //                         <ListItemIcon>
    //                             {index % 2 === 0 ? <HomeOutlinedIcon color='primary' fontSize='medium' /> : <LogoutIcon color='primary' fontSize='medium' />}
    //                         </ListItemIcon>
    //                         <ListItemText color='text.primary' primary={text} />
    //                     </ListItemButton>
    //                 </ListItem>
    //             ))}
    //         </List>

    //     </Box >
    // );


    return (
        <>
            {/* <Container maxWidth={'xl'} disableGutters component={"header"} sx={{ backgroundColor: isPhoneScreen ? theme.palette.secondary.main : theme.palette.common.white, }} > */}
            <Container sx={{ minWidth: '100%', padding: 0 }} className='header' >
                <Container component={"header"} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                        <Box sx={{ display: { xs: 'none', md: 'flex', justifyContent: 'flex-start', }, mr: 1, }}>
                            <img style={{ width: '11rem', height: '5rem', }} src={`${images.headerLogo15}`} alt='logo' />
                        </Box>
                        <Box sx={{ flexGrow: 0, display: { md: 'flex' } }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <IconButton sx={{ p: 0 }}>
                                    <Avatar alt="Shubham Gulik" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                                <Box sx={{ ml: 0, display: { sm: "none", xs: "none", md: "block" } }}>
                                    <Typography sx={{ ml: 1, fontWeight: 700, }} variant="body1"> Shubham Gulik</Typography>
                                    <Typography sx={{ ml: 1, fontWeight: 400, }} variant="body2"> Ministry of Public Health</Typography>
                                </Box>
                                <Box sx={{ display: { sm: "none", xs: "none", md: "block", }, justifyContent: 'center', alignItems: 'center', }} ><LogoutIcon onClick={() => { navigate("/login") }} color='primary' sx={{ ml: 2, cursor: 'pointer' }} /></Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Container>
        </>
    );
}
export default DesktopAppHeader;
