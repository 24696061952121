import { Box, Container, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducer'
import MobileFacilityCard from '../../shared/components/MobileFacilityCard/MobileFacilityCard'
import MobileSearchBox from '../../shared/components/MobileSearchBox/MobileSearchBox'
import MobileVaccineCard from '../../shared/components/MobileVaccineCard/MobileVaccineCard'
import theme from '../../theme'
import CustomNoRowsOverlay from '../../shared/components/CustomNoRowsOverlay/CustomNoRowsOverlay'


const styles = {
    container: {
        backgroundColor: theme.palette.secondary.main,
        minHeight: "90vh",
        width: "100vw",
        pb: 2,
        borderTop: "0.1px solid white",
    },
    facillityCard: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "4rem",
        backgroundColor: theme.palette.common.white,
        width: "100%",
        border: "1px transparent",

    },

}


type Props = {
    handleSearch: (searchQuery: string) => void
    handleVaccineClick: (id: string) => void
}

function MobileVaccineList({ handleSearch, handleVaccineClick }: Props) {
    const filteredVaccines = useSelector((state: RootState) => state.vaccine.filteredVaccines);
    const isGridEmpty = filteredVaccines.length === 0;

    return (
        <>
            <MobileFacilityCard />
            <Container sx={styles.container}>
                <MobileSearchBox placeholder='Search Vaccination' handleSearch={handleSearch} />
                <Box sx={{ mt: 3 }} >
                    <Typography variant='body2' color={theme.palette.text.secondary} mb={1} >Total Results : {filteredVaccines.length} </Typography>
                    <Typography variant='body2' fontWeight={theme.typography.fontWeightMedium} >Please note : Only the main registry's web portal can add vaccine lots for visibility here.</Typography>
                </Box>
                <Grid container rowGap={3} mt={3} justifyContent={"space-between"}>
                    {!isGridEmpty && filteredVaccines?.map((row, index) => (
                        <MobileVaccineCard key={index} {...row} handleVaccineClick={handleVaccineClick} />
                    ))}
                    {isGridEmpty && (
                        <div style={{ margin: "auto", }}>
                            {/* <Typography variant='h4' >No data available</Typography> */}
                            <CustomNoRowsOverlay />
                        </div>
                    )}
                </Grid>
            </Container>
        </>
    )
}

export default MobileVaccineList