import { Box, Button, Container, Grid, InputLabel, OutlinedInput, Paper, Typography } from '@mui/material';
import MobileFacilityCard from '../../shared/components/MobileFacilityCard/MobileFacilityCard';
import theme from '../../theme';

import MobileVaccineSpotlight from '../../shared/components/VaccineSpotlight/MobileVaccineSpotlight';


const styles = {
    container: {
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
        width: "100vw",
        // border: 1,
    },

    mobileVaccineCard: {
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        // alignItems: "flex-start",
        padding: "1rem",
        border: 1,
        // width: "100%",

    },

    bottomPaper: {
        position: 'fixed',
        bottom: 0, left: 0,
        right: 0, height: 70,
        // border: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingX: 2,
        backgroundColor: "#F2F1FA",
    },
    formHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inputBox: {
        mt: 0.5,
        borderRadius: "borderRadius",
        backgroundColor: '#FFF',
        fontWeight: 400
    }

}


type Props = {
    handleSearch: (searchQuery: string) => void;
    formData: {
        qid: string;
        hcNo: string;
        passport: string;
        visa: string;
        phoneNo: string;
        state: string;
    };
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    clearSearch: () => void
}

function MobilePatientSearch({ handleSearch, formData, handleInputChange, clearSearch }: Props) {


    return (

        <Box sx={{ backgroundColor: theme.palette.secondary.main, border: "0.1px solid transparent", flex: 1, height: "100vh" }}>
            <MobileFacilityCard />
            <MobileVaccineSpotlight />

            <Container sx={{ mt: 1, backgroundColor: theme.palette.common.white, pt: 2, pb: 2, mb: 10 }}>
                <Box sx={styles.formHeader} >
                    <Typography variant='body2' sx={{ fontSize: "1rem", fontWeight: 500, color: theme.palette.text.primary }} >Patient Search</Typography>
                    <Typography variant='body2' onClick={clearSearch} sx={{ fontSize: "0.8rem", fontWeight: 500, color: theme.palette.primary.main }} >Clear Search</Typography>
                </Box>
                < Grid container borderRadius={theme.shape.borderRadius} display={'flex'} justifyContent={'space-between'} alignItems={'center'} rowGap={2} mt={2} columnGap={1}  >
                    <Grid item xs={12} sm={5.5}  >
                        <InputLabel htmlFor="qid" >
                            <Typography variant='body2' sx={{ fontSize: "0.8rem", fontWeight: 500, color: theme.palette.text.primary }}   >QID</Typography>
                        </InputLabel>
                        <OutlinedInput
                            sx={styles.inputBox}
                            name='qid'
                            value={formData.qid}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            id="qid"
                            autoComplete="qid"
                            size='small'
                            type='text'
                        />
                    </Grid>
                    <Grid item xs={12} sm={5.5} >
                        <InputLabel htmlFor="hc" >
                            <Typography variant='body2' sx={{ fontSize: "0.8rem", fontWeight: 500, color: theme.palette.text.primary }}  >HC No</Typography>
                        </InputLabel>
                        <OutlinedInput
                            sx={styles.inputBox}
                            name='hcNo'
                            value={formData.hcNo}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            id="hc"
                            autoComplete="hc"
                            size='small'

                        />
                    </Grid>

                    <Grid item xs={12} sm={5.5} >
                        <InputLabel htmlFor="passport" >
                            <Typography variant='body2' sx={{ fontSize: "0.8rem", fontWeight: 500, color: theme.palette.text.primary }}  >Passport</Typography>
                        </InputLabel>
                        <OutlinedInput
                            sx={styles.inputBox}
                            name='passport'
                            value={formData.passport}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            id="passport"
                            autoComplete="email"
                            size='small'

                        />
                    </Grid>
                    <Grid item xs={12} sm={5.5} >
                        <InputLabel htmlFor="visa" >
                            <Typography variant='body2' sx={{ fontSize: "0.8rem", fontWeight: 500, color: theme.palette.text.primary }}  >Visa</Typography>
                        </InputLabel>
                        <OutlinedInput
                            sx={styles.inputBox}
                            name='visa'
                            value={formData.visa}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            id="visa"
                            autoComplete="visa"
                            size='small'

                        />
                    </Grid>
                    <Grid item xs={12} sm={5.5} >
                        <InputLabel htmlFor="phone" >
                            <Typography variant='body2' sx={{ fontSize: "0.8rem", fontWeight: 500, color: theme.palette.text.primary }}  >Phone Number</Typography>
                        </InputLabel>
                        <OutlinedInput
                            sx={styles.inputBox}
                            name='phoneNo'
                            value={formData.phoneNo}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            id="phone"
                            autoComplete="phone"
                            size='small'

                        />
                    </Grid>
                    <Grid item xs={12} sm={5.5} >
                        <InputLabel htmlFor="state" >
                            <Typography variant='body2' sx={{ fontSize: "0.8rem", fontWeight: 500, color: theme.palette.text.primary }}  >State</Typography>
                        </InputLabel>
                        <OutlinedInput
                            sx={styles.inputBox}
                            name='state'
                            value={formData.state}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            id="state"
                            autoComplete="state"
                            size='small'

                        />
                    </Grid>
                </Grid>
            </Container >

            <Paper sx={styles.bottomPaper} elevation={3}>
                <Button fullWidth sx={{ borderRadius: "4px", }} onClick={() => { handleSearch("casa ") }} variant="contained" >Search</Button>
            </Paper>
        </Box>



    )
}

export default MobilePatientSearch