import { createTheme } from "@mui/material";


const theme = createTheme({
    palette: {
        mode: 'light', // Change to 'dark' for dark mode
        primary: {
            main: '#8A1538', // Change this to your primary color
            // Other possible properties:
            // light: '#6ab7ff',
            // dark: '#004ba0',
            // contrastText: '#fff',
        },
        secondary: {
            main: '#F2F1FA', // Change this to your secondary color
            // Other possible properties:
            // light: '#ff5983',
            // dark: '#bb002f',
            // contrastText: '#fff',
        },
        // Additional palette properties:
        // error: { main: '#f44336' },
        // warning: { main: '#ff9800' },
        info: { main: '#000000' },
        // success: { main: '#4caf50' },
        background: {
            default: '#FFF',
            paper: '#fff',
        },
        text: { primary: 'rgba(0, 0, 0, 0.87)', secondary: 'rgba(0, 0, 0, 0.54)', disabled: 'rgba(0, 0, 0, 0.38)' },
        common: {
            white: '#ffffff',
            black: '#000000',
        },
    },
    // Typography options:
    typography: {
        fontFamily: 'Segoe UI',
        fontSize: 16,

        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,

        h1: { fontWeight: 700, fontSize: '2rem', lineHeight: 1.6, letterSpacing: '-0.01562em' },
        h2: { fontWeight: 700, fontSize: '1.7rem', lineHeight: 1.334, letterSpacing: '-0.00833em' },
        h3: { fontWeight: 700, fontSize: '1.5rem', lineHeight: 1.235, letterSpacing: '0em' },
        h4: { fontWeight: 600, fontSize: '1.3rem', lineHeight: 1.167, letterSpacing: '0.00735em' },
        h5: { fontWeight: 500, fontSize: '1.2rem', lineHeight: 1.2, letterSpacing: '0em' },
        h6: { fontWeight: 500, fontSize: '1.2rem', lineHeight: 1.1, letterSpacing: '0.0075em' },
        subtitle1: { fontWeight: 400, fontSize: '1rem', lineHeight: 1, letterSpacing: '0.00938em' },
        subtitle2: { fontWeight: 500, fontSize: '0.875rem', lineHeight: 1.57, letterSpacing: '0.00714em' },
        body1: { fontWeight: 400, fontSize: '1rem', lineHeight: 1.5, letterSpacing: '0.00938em' },
        body2: { fontWeight: 400, fontSize: '1rem', lineHeight: 1.43, letterSpacing: '0.01071em' },
        button: { fontWeight: 500, fontSize: '0.90rem', lineHeight: 1.75, letterSpacing: '0.02857em', textTransform: 'capitalize' },
        caption: { fontWeight: 400, fontSize: '0.75rem', lineHeight: 1.66, letterSpacing: '0.03333em' },
        overline: { fontWeight: 400, fontSize: '0.75rem', lineHeight: 2.66, letterSpacing: '0.08333em', textTransform: 'uppercase' }
    },
    // Spacing options:
    spacing: 8,
    // Shape options:
    shape: { borderRadius: 2, },
    // Transitions options:
    // transitions: { easing: { easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)', easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)', easeIn: 'cubic-bezier(0.4, 0, 1, 1)', sharp: 'cubic-bezier(0.4, 0, 0.6, 1)' }, duration: { shortest: 150, shorter: 200, short: 250, standard: 300, complex: 375, enteringScreen: 225, leavingScreen: 195 } },
    // zIndex options:
    zIndex: { mobileStepper: 1000, appBar: 1100, drawer: 1200, modal: 1300, snackbar: 1400, tooltip: 1500 },
    // Breakpoints options:
    // breakpoints: { keys: ['xs', 'sm', 'md', 'lg', 'xl'], values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 } },
    // Direction options:
    // direction: 'ltr',
    // Components options:
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    // Change background color for small screens
                    '@media (max-width:768px)': {
                        root: {
                            backgroundColor: '#F2F1FA', // Change this to your desired color
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 4
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#000000'
                }
            }
        }
    },
});

theme.palette.background = {
    // '@media (min-width:600px)': {
    //     fontSize: '1.5rem',
    // },
    default: '#FFF',
    paper: "#fff",
    [theme.breakpoints.down('md')]: {
        default: '#F2F1FA',
        paper: "#F2F1FA"
    },
};

export default theme;
