import { Dispatch, combineReducers } from "redux";
import facilityReducer from "./features/facility/facilitySlice";
import vaccineReducer from "./features/vaccine/vaccineSlice";
const rootReducer = combineReducers({

    facility: facilityReducer,
    vaccine: vaccineReducer
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = Dispatch<any>; // Adjust `any` if you have specific action types
