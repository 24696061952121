import { Box, Container, Grid, } from '@mui/material';
import StickyFooter from '../../shared/components/StickyFooter/StickyFooter';
import VaccineFacilityCard from '../../shared/components/VaccineFacilityCard/VaccineFacilityCard';
import theme from '../../theme';

const styles = {
    container: {
        flexGrow: 1
    },
    vaccineContainer: { mb: 10, },
    gridStyles: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        // border: 1
    }
}

type Props = {
    list: {
        name: string,
        icon: string
    }[]
}

function DesktopCategories({ list }: Props) {

    return (
        <>
            <Container sx={styles.container}>
                <Box width={"100%"} sx={styles.vaccineContainer}   >
                    <Grid container rowGap={3} sx={styles.gridStyles} >
                        {list?.map((item, index) => (
                            <Grid key={item.name} item xs={12} md={5.5} sx={{ backgroundColor: theme.palette.common.white }} >
                                <VaccineFacilityCard title={item.name} to={"/facilities"} icon={item.icon} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default DesktopCategories