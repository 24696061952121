import React, { useEffect } from "react";
import VaccineSpotlight from "../../shared/components/VaccineSpotlight/VaccineSpotlight";
import DesktopFacility from "./DesktopFacility";
import MobileFacility from "./MobileFacility";
import { useSelector } from 'react-redux';
import { Box } from "@mui/material";
import StickyFooter from "../../shared/components/StickyFooter/StickyFooter";
import { useDispatch } from "react-redux";
import { getAllFacilities } from "../../redux/features/facility/facilitySlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../redux/reducer";
import { FacilityType } from "../../shared/types";
// const list = ["Ministry of Public Health (1001)", "American Hospital Doha (1050)", "Qatar Airways", "Hamad Medical Corporation"];
// const styles = {
//     facilityContainer: { mb: 10, borderRadius: 2, },
//     gridStyles: { flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'center' }
// }
function Facility({ isPhoneScreen }: { isPhoneScreen: boolean }) {
    const facilities = useSelector((state: any) => state.facility.facilities)
    const [filteredList, setFilteredList] = React.useState(facilities)



    const handleSearch = (searchQuery: string) => {
        const temp = facilities.filter((item: any) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
        setFilteredList(temp);
    }

    useEffect(() => {

        if (facilities && facilities.length > 0) {
            setFilteredList(facilities);
        }
    }, [facilities])

    return (
        <>
            {isPhoneScreen ?
                <MobileFacility
                    list={filteredList}
                    handleSearch={handleSearch}
                />
                :
                <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <VaccineSpotlight handleSearch={handleSearch} />
                    <DesktopFacility
                        list={filteredList}
                    />
                    <StickyFooter />
                </Box>
            }
        </>
    )
}

export default Facility