import axios from "axios";
import { useAlert } from "../../modules/alerts/useAlert";
import config from "./apiConfig.json";
import { Constants } from "../constants";

const _baseUrl = config.general.baseUrl;
export const RequestMethod = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
}

export const Headers = {
    AUTHORIZATION: "Authorization",
    CONTENTTYPE: "Content-Type",
}

const appClient = axios.create({
    baseURL: _baseUrl,
    headers: {
        Accept: "application/json",
    },
});

appClient.interceptors.request.use(
    function (config) {
        const value = localStorage.getItem("accessToken");
        config.headers["Authorization"] = `Bearer ${value}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export const appRequest = (
    options,
    { showToast = true, fullResponse = false, afterSuccess = () => { } } = {}
) => {
    // requestCount++;  
    const onSuccess = function (response) {
        afterSuccess && afterSuccess();
        if (response && response.data) {
            return fullResponse ? response : response.data;
        } else {
            return false;
        }
    };

    const onError = (error) => {
        let errorMessage = {
            title: "Error",
            message:
                error?.response?.data?.error ||
                Constants.GENERIC_ERROR,
            status: false,
            httpStatusCode: error.response?.status || 500,
        };


        if (showToast) {
            // const { showAlert } = useAlert();
            // showAlert(errorMessage.message, "error");
            console.log("Error: ", errorMessage);

        }

        if (errorMessage.httpStatusCode == 401) {
            // clear storage and redux
            localStorage.clear();
            window.location = '/login';

        }
        return Promise.reject(errorMessage);
    };

    return appClient(options).then(onSuccess).catch(onError);
};

