import { Box, Container, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { images } from '../../../assets/images'
import { getAllFacilities, getFacilitieById } from '../../../redux/features/facility/facilitySlice'
import theme from '../../../theme'
import useQuery from '../../hooks/useQuery'
import { AppDispatch, RootState } from '../../../redux/reducer'
type Props = {}
const styles = {

    facillityCard: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "4rem",
        backgroundColor: theme.palette.common.white,
        width: "100%",
        // border: "1px solid #F9B129",

    },
}


function MobileFacilityCard({ }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const query = useQuery();
    const facility = useSelector((state: RootState) => state.facility.facility)

    let facilityId = query.get("facilityId");


    const getFacilitiesAsync = async () => {
        await dispatch(getAllFacilities());
        if (facilityId) {
            await dispatch(getFacilitieById({ id: facilityId }));
        }
    }

    useEffect(() => {
        getFacilitiesAsync();

    }, [facilityId])



    return (
        <Container sx={styles.facillityCard}>
            <img src={`${images.facility2x}`} style={{ width: 45, height: 45, }} alt='logo' />
            <Box ml={2}>
                <Typography variant='body2' >Facility</Typography>
                <Typography variant='body2' fontWeight={theme.typography.fontWeightMedium} >{facility?.name}</Typography>
            </Box>
        </Container>
    )
}

export default MobileFacilityCard