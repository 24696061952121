import { Box, Container } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AlertDialog from '../../shared/components/AlertDialog/AlertDialog'
import StickyFooter from '../../shared/components/StickyFooter/StickyFooter'
import VaccineSpotlight from '../../shared/components/VaccineSpotlight/VaccineSpotlight'
import { Constants } from '../../shared/constants'
import DesktopPatientDetails from './DesktopPatientDetails'
import MobilePatientDetails from './MobilePatientDetails'
import { images } from '../../assets/images';
type Props = {
    isPhoneScreen: boolean
}

const patientData = [
    {
        "label": "Full Name",
        "value": "John Doe"
    },
    {
        "label": "QID",
        "value": "2845367"
    },
    {
        "label": "HC No.",
        "value": "2845946"
    },
    {
        "label": "Birth Date",
        "value": "24-07-1986"
    },
    // {
    //     "label": "SIIS Patient ID",
    //     "value": "2845679"
    // },
    {
        "label": "Passport",
        "value": "ADE1452654"
    },
    {
        "label": "Visa",
        "value": "123456"
    },
    {
        "label": "State",
        "value": "Doha"
    },
    {
        "label": "Phone Number",
        "value": "+974 9547226461"
    },
    {
        "label": "Entry Date",
        "value": "09-04-2021 05:26 PM"
    },
    {
        "label": "Organization Owner",
        "value": "1005 - Hamad Medical Corporation"
    },
    {
        "label": "Last Updated By",
        "value": "Hussain Malik Asif"
    },
    {
        "label": "Facility Owner",
        "value": "24 - Hamad General Hospital"
    },
    {
        "label": "Entered By",
        "value": "ACCRO Import"
    }
]

const vaccineDatesList: any = [
    {
        title: "Coronavirus (SARS-CoV-2)(COVID-19)",
        dates: [
            "2024-03-05",
            "2024-03-10",
            "2024-03-15",
            "2024-03-15",
        ]
    },
    {
        title: "COVID-19, mRNA, LNP-S, PF, 30 mcg/0.3 ml dose",
        dates: [
            "2024-03-05",
            "2024-03-10",
            "2024-03-15",
            "2024-03-15",
        ]
    },
    {
        title: "DTap/DT/Td",
        dates: [
            "2024-03-05",
            "2024-03-10",
            "2024-03-15",
            "2024-03-15",
        ]
    },
    {
        title: "Coronavirus (SARS-CoV-2)(COVID-19)",
        dates: [
            "2024-03-05",
            "2024-03-10",
            "2024-03-15",
            "2024-03-15",
        ]
    },
]


function PatientDetails({ isPhoneScreen }: Props) {
    const [showModal, setShowModal] = React.useState(false);
    const navigate = useNavigate();
    const vaccine = useSelector((state: any) => state.vaccine.vaccine)
    const facility = useSelector((state: any) => state.facility.facility)


    // const hasQueryParams = location.search !== '';

    // React.useEffect(() => {
    //     if (!hasQueryParams) {
    //         navigate('/');
    //     }
    // }, [hasQueryParams, navigate]);


    const handleAction1 = () => {
        navigate("/patient-search?facilityId=" + facility?.id + "&vaccineId=" + vaccine?.id);
        setShowModal(false);
    }
    const handleAction2 = () => {
        navigate("/");
        setShowModal(false);
    }

    const handleCloseAction = () => {
        setShowModal(false);
    }

    return (
        <>
            {showModal &&
                <AlertDialog
                    title={Constants.SUCCESS}
                    subTitle={Constants.RECORD_SAVED}
                    visible={showModal}
                    // icon={Constants.SUCCESS_ICON}
                    icon={images.successIcon2x}
                    action1={"Continue"}
                    action2={"Back to Home"}
                    handleAction1={handleAction1}
                    handleAction2={handleAction2}
                    handleCloseAction={handleCloseAction}
                />
            }

            {isPhoneScreen ?
                <MobilePatientDetails patientData={patientData} vaccineDatesList={vaccineDatesList} setShowModal={setShowModal} />
                :
                <Box style={{ height: '100%', display: 'flex', flexDirection: 'column', border: 1 }}>
                    <VaccineSpotlight />
                    {/* <Container maxWidth="xl"> */}
                    <DesktopPatientDetails patientData={patientData} vaccineDatesList={vaccineDatesList} setShowModal={setShowModal} />
                    {/* </Container> */}
                    <StickyFooter />
                </Box>
            }
        </>
    )
}

export default PatientDetails