import { Container, Grid, Typography } from '@mui/material';
import MobileSearchBox from '../../shared/components/MobileSearchBox/MobileSearchBox';
import VaccineFacilityCard from '../../shared/components/VaccineFacilityCard/VaccineFacilityCard';
import theme from '../../theme';
import { AppDispatch } from '../../redux/reducer';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getAllFacilities } from '../../redux/features/facility/facilitySlice';

type Props = {
    list: {
        id: string;
        name: string;
    }[]
    // handleFacilityClick: (name: string) => void
    handleSearch: (searchQuery: string) => void
}

const styles = {
    container: {
        backgroundColor: theme.palette.secondary.main,
        height: "100vh",
    }
}



function MobileFacility({ list, handleSearch }: Props) {

    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllFacilities());
    }, [])


    // const handleSearch = (searchQuery: string) => {
    //     // console.log(searchQuery);
    // }
    return (
        <Container maxWidth="xl" sx={styles.container}>
            <Typography variant='h5' mb={3} >Choose your Facility for this Session</Typography>
            <MobileSearchBox placeholder='Search Facility' handleSearch={handleSearch} />
            <Grid container rowGap={3} mt={3}  >
                {list?.map((facility: { id: string, name: string }) => (
                    <Grid key={facility.id} item xs={12} md={5.5} sx={{ backgroundColor: theme.palette.common.white }} >
                        <VaccineFacilityCard key={facility.id} title={facility.name} to={`/vaccine-list?facilityId=${facility.id}`} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default MobileFacility