import SearchIcon from '@mui/icons-material/Search';
import theme from '../theme';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
export const Constants = {
    GENERIC_ERROR: "Some error occurred while performing operation.",
    PATIENT_NOT_FOUND: "Patient Not Found",
    DO_YOU_WANT_TO_ADD: "Do you want to add?",
    SEARCH_ICON: <SearchIcon sx={{ fontSize: '5rem', color: theme.palette.primary.main }} />,
    SUCCESS_ICON: <CheckCircleOutlineIcon sx={{ fontSize: '5rem', color: theme.palette.primary.main }} />,
    WARNING_ICON: <WarningAmberRoundedIcon sx={{ fontSize: '5rem', color: theme.palette.primary.main }} />,
    BACK_TO_VACCINE_LIST: "Back to Select Vaccine List",
    BACK_TO_PATIENT_SEARCH: "Back to Patient Search",
    COPYRIGHT_TEXT: "Ministry of Public Health.",
    SUCCESS: "Success",
    RECORD_SAVED: "Record saved successfully",
    ALLERGIC: "Allergic",
    PROBABLE_ALERGY: "Patient has a Probable allergy to...",
}