import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVaccines, getVaccineById } from '../../../redux/features/vaccine/vaccineSlice';
import { AppDispatch, RootState } from '../../../redux/reducer';
import theme from '../../../theme';
import useQuery from '../../hooks/useQuery';


const styles = {
    cardTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        // border: 1,
        width: "97%",
    },
    cardContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "90%",
    },
}

type Props = {}

function MobileVaccineSpotlight({ }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const query = useQuery();
    const vaccine = useSelector((state: RootState) => state.vaccine.vaccine);

    let vaccineId = query.get("vaccineId");

    const fillData = async () => {
        await dispatch(getAllVaccines());
        if (vaccineId) {
            await dispatch(getVaccineById({ id: vaccineId }));
        }
    }

    useEffect(() => {
        fillData();
    }, [vaccineId])

    return (
        <Accordion sx={{ mt: 1, boxShadow: 0, border: 0 }} >
            <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon color='primary' />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box sx={styles.cardTitle} >
                    <Typography variant='body2' color={theme.palette.text.secondary}  >Vaccination</Typography>
                    <Typography variant='body2' sx={{ fontSize: "0.9rem", fontWeight: 500, color: theme.palette.text.primary }} >{vaccine.vaccine}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={styles.cardContent} >
                    <Box>
                        <Typography variant='body2' color={theme.palette.text.secondary}  >LoT</Typography>
                        <Typography variant='body2' sx={{ fontSize: "0.9rem", fontWeight: 500, color: theme.palette.text.primary }} >{vaccine.LoT}</Typography>
                    </Box>
                    <Box>
                        <Typography variant='body2' color={theme.palette.text.secondary}  >Expiry Date</Typography>
                        <Typography variant='body2' sx={{ fontSize: "0.9rem", fontWeight: 500, color: theme.palette.text.primary }} >{vaccine.expiry}</Typography>
                    </Box>
                    <Box>
                        <Typography variant='body2' color={theme.palette.text.secondary}  >Quantity</Typography>
                        <Typography variant='body2' sx={{ fontSize: "0.9rem", fontWeight: 500, color: theme.palette.text.primary }}>{vaccine.qty}</Typography>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion >
    )
}

export default MobileVaccineSpotlight