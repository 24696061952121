
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import React, { useState } from 'react';
import BackNavigate from '../../shared/components/BackNavigate/BackNavigate';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import VaccineDates from '../../shared/components/VaccineDates/VaccineDates';
import { Constants } from '../../shared/constants';
import theme from '../../theme';
import './PatientDetails.css';
import { useSelector } from 'react-redux';





const styles = {
    vaccineContainer: {
        backgroundColor: '#EDEDED66',
        paddingY: '1rem',
        borderRadius: theme.shape.borderRadius,
        mb: 2,
        [theme.breakpoints.down('md')]: {
            width: "100%",
            backgroundColor: theme.palette.common.white,
        },
    },
    headerTitle: {
        // fontSize: '1.5rem',
        // color: theme.palette.common.black,
        // fontWeight: 600
    },
    headerButton: {
        borderRadius: theme.shape.borderRadius,
    },
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mb: 2
    },
    gridStyle: {
        transition: "height 10s ease-in-out",
        // width: "90%"
        // border: 1
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    bottomPaper: {
        position: 'fixed',
        bottom: 0, left: 0,
        right: 0, height: 70,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingX: 2,
        backgroundColor: theme.palette.background.default
    },
    footer: {
        [theme.breakpoints.down('md')]: {
            display: "none"
        },

    },
    bottomSearchContainer: {
        [theme.breakpoints.up('md')]: {
            display: "none"
        },
    },
    accordianContainer: {
        // [theme.breakpoints.up('md')]: {
        //     display: "none"
        // },
    },
    cardTitle: {

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        // border: 1,
        width: "97%",
    },
    cardContent: {

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "90%",
    },
    container: {

        flexGrow: 1,
        mb: "5rem",
    }
}





type Props = {
    patientData:
    {
        label: string,
        value: string
    }[]
    ;
    vaccineDatesList:
    {
        title: string,
        dates: moment.Moment[]
    }[];
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}



function DesktopPatientDetails({ patientData, vaccineDatesList, setShowModal }: Props) {
    const [expanded, setExpanded] = useState(false);
    const vaccine = useSelector((state: any) => state.vaccine.vaccine)
    const vaccineDates = useSelector((state: any) => state.vaccine.vaccineDates);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Container sx={styles.container} >
                <BackNavigate title={Constants.BACK_TO_PATIENT_SEARCH} />
                <SectionTitle title={"Patient Details"} />

                <Container maxWidth={"xl"} sx={styles.vaccineContainer}  >
                    <Grid maxWidth={"xl"} container sx={styles.gridStyle}  >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} textAlign={"left"}>
                            <Typography variant='h6'  >Patient Info</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} textAlign={"right"}   >
                            <Button sx={styles.headerButton} variant="contained" onClick={handleExpand} startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>{expanded ? "View Less" : "View More"} </Button>
                        </Grid>
                        <Grid container rowGap={4} mt={2}  >
                            {patientData.map((item, index) => (
                                index >= 8 ?
                                    (expanded ?
                                        <Grid key={item.label} item xs={6} sm={6} md={3} lg={3} xl={3}>
                                            <Typography variant='body2' fontSize={"0.9rem"} mb={1}>{item.label}</Typography>
                                            <Typography variant='body2' fontWeight={theme.typography.fontWeightMedium}>{item.value}</Typography>
                                        </Grid> : null)
                                    :
                                    <Grid key={item.label} item xs={6} sm={6} md={3} lg={3} xl={3}>
                                        <Typography variant='body2' fontSize={"0.9rem"} mb={1}>{item.label}</Typography>
                                        <Typography variant='body2' fontWeight={theme.typography.fontWeightMedium}>{item.value}</Typography>
                                    </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Container>

                <Container sx={styles.vaccineContainer}>
                    <Box sx={styles.headerContainer}>
                        <Typography variant='h6'  >Vaccine Info</Typography>
                        {/* <Button sx={styles.headerButton} variant="outlined">Download Certificate</Button> */}
                    </Box>
                    {/* {vaccineDatesList?.map((item, index) => ( */}

                    <VaccineDates key={vaccine?.vaccine} title={vaccine.vaccine} dates={vaccineDates} setShowModal={setShowModal} />
                    {/* ))} */}

                </Container>
                <Container sx={styles.vaccineContainer}>
                    <Box sx={styles.headerContainer}>
                        <Typography variant='h6'  >Vaccine History</Typography>
                        {/* <Button sx={styles.headerButton} variant="outlined">Download Certificate</Button> */}
                    </Box>
                    {vaccineDatesList?.map((item, index) => (
                        <VaccineDates key={item.title + index} title={item.title} dates={item.dates} setShowModal={setShowModal} hideButtons={true} />
                    ))}
                </Container>
            </Container >
        </>
    );

}

export default DesktopPatientDetails