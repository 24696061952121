import { Box, Button, Container } from '@mui/material';

import { GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { filterVaccines, getAllVaccines } from '../../redux/features/vaccine/vaccineSlice';
import { AppDispatch, RootState } from '../../redux/reducer';
import StickyFooter from '../../shared/components/StickyFooter/StickyFooter';
import VaccineSpotlight from '../../shared/components/VaccineSpotlight/VaccineSpotlight';
import DesktopVaccineList from './DesktopVaccineList';
import MobileVaccineList from './MobileVaccineList';
import "./VaccineList.css";
import React, { useEffect } from 'react';




type props = {
    isPhoneScreen: boolean
}

function VaccineList({ isPhoneScreen }: props) {
    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch();
    const facility = useSelector((state: RootState) => state.facility.facility);
    const [facilityX, setFacilityX] = React.useState(facility);

    const handleSearch = (searchQuery: string) => {
        dispatch(filterVaccines(searchQuery));
    };

    const handleVaccineClick = (id: string) => {
        navigate(`/patient-search?facilityId=${facility.id}&vaccineId=${id}`);
    }

    const mockFn = async (): Promise<void> => {
        try {
            await dispatch(getAllVaccines());
            // if ('payload' in actionResult) {
            //     console.log("vaccines", actionResult.payload);
            // } else if ('error' in actionResult) {
            //     const error = actionResult.error as Error;
            //     console.log("Error:", error ? error.message : "Unknown error occurred");
            // }
        } catch (error) {
            console.log("Error:", error);
        }
    }

    useEffect(() => {
        mockFn();
    }, [facilityX])





    const columns: GridColDef[] = [
        { field: 'vaccine', headerName: 'Vaccination', flex: 2, },
        { field: 'LoT', headerName: 'LoT', flex: 1, },
        { field: 'expiry', headerName: 'Expiry Date', flex: 1, },
        {
            field: 'qty',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',

        },
        {
            field: 'Action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            align: 'center',
            headerAlign: 'center',


            renderCell: (params) => {

                return (
                    <Button id={params.row.id} sx={{ borderRadius: "4px", }} onClick={() => handleVaccineClick(params.row.id)} variant="contained">Select</Button>
                );
            },
        },
    ];


    return (
        < >

            {isPhoneScreen ?
                <MobileVaccineList
                    handleSearch={handleSearch}
                    handleVaccineClick={handleVaccineClick}
                />
                :
                <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                    <VaccineSpotlight />
                    <DesktopVaccineList
                        columns={columns}
                        handleSearch={handleSearch}
                    />
                    <StickyFooter />
                </Box>
            }
        </>
    )
}



export default VaccineList