import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import * as React from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, InputLabel, OutlinedInput, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { images } from '../../assets/images';
import theme from '../../theme';

const styles = {
    imageGrid: {
        backgroundImage: `url("${images.login3x}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        margin: 0,
    },
    rightGrid: { display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FFF" },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "flex-start",
        width: "70%",
        [theme.breakpoints.down('sm')]: {
            width: '90%', // Set width to 90% on small screens and above
        },
    },
    avatar: {
        m: 1,
        bgcolor: 'transparent',
        width: 180,
        height: 145,
        [theme.breakpoints.down('sm')]: {
            width: 150,
            height: 130,
        },
    },
    loginTitleContainr: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "0px",

        },
    },
    inputStyle: {
        mt: 1.3, mb: 2,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
    },

}
function Copyright(props: any) {
    return (
        <Typography variant="body2" align="center" {...props}>
            <Typography variant="body2" align='center' mb={2}>
                Vaccination Immunization Portal
            </Typography>
        </Typography>
    );
}


export default function Login() {
    const navigate = useNavigate();
    const isPhoneScreen = useMediaQuery('(max-width: 900px)');
    if (isPhoneScreen) {
        styles.rightGrid.backgroundColor = "#F2F1FA";
    }

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    return (
        <>
            <Grid container component="main" sx={{ minHeight: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={false}
                    md={7}
                    lg={7}
                    sx={styles.imageGrid}
                >
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} component={Paper} elevation={6} sx={styles.rightGrid} >
                    <Box sx={[styles.formContainer]}>
                        <Avatar variant='square' sx={styles.avatar}>
                            <img src={`${images.logo}`} style={{ width: '100%', height: '100%' }} alt='Profile Icon' />
                        </Avatar>
                        <Box sx={styles.loginTitleContainr}>
                            <Typography variant="h2"   >
                                Welcome Back
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mt: 1, }} >
                                Log in to your MOPH account
                            </Typography>
                        </Box>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: "100%" }} >
                            <InputLabel htmlFor="email" sx={{ color: 'text.primary' }}>Email</InputLabel>
                            <OutlinedInput
                                sx={[styles.inputStyle]}
                                required
                                fullWidth
                                id="email"
                                autoComplete="email"
                                autoFocus
                                size='small'
                                placeholder={isPhoneScreen ? "Email" : ""} />

                            <InputLabel htmlFor="password" sx={{ color: 'text.primary' }}>Password</InputLabel>
                            <OutlinedInput
                                sx={styles.inputStyle}
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <img src={`${images.eye2x}`} style={{ width: '1.5rem', height: '0.5rem' }} alt='Eye Close' />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                name="password"
                                required
                                fullWidth
                                size='small'
                                placeholder={isPhoneScreen ? "Password" : ""}
                            />

                            <InputLabel htmlFor="organization" sx={{ color: 'text.primary' }}>Organization</InputLabel>
                            <OutlinedInput
                                sx={styles.inputStyle}
                                required
                                fullWidth
                                id="organization"
                                name="organization"
                                autoComplete="organization"
                                // value={"Ministry of Public Health"}
                                size='small'
                                placeholder={isPhoneScreen ? "Organization" : ""}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1.5, mb: 2 }}
                                color='primary'
                                size='large'
                                onClick={() => navigate('/')}

                            >
                                Login
                            </Button>
                            <Grid container >
                                <Grid item xs sx={{ justifyContent: "center", alignItems: "center", alignContent: "center", alignSelf: "center" }}>
                                    <Link sx={{ display: "flex", justifyContent: "center", color: "primary", textDecoration: "none" }} href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 2 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid >
        </>
    );
}