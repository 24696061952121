import { Box, Button, InputAdornment, OutlinedInput, Typography } from '@mui/material'
import theme from '../../../theme';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';


type Props = {
    title?: string
    handleSearch?: (value: string) => void
    placeholder?: string
}

function SearchFeature({ title, handleSearch, placeholder }: Props) {
    const [searchQuery, setSearchQuery] = React.useState('');
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
                <Typography sx={{ color: '#FFF', fontSize: '1.2rem', fontWeight: 700 }}>
                    {title}
                </Typography>
            </Box>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, height: 50 },
                    display: {
                        md: "flex",
                        sm: "flex",
                        xs: "none"
                    },
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: '75ch',
                }}
                noValidate
                autoComplete="off"
            >

                <OutlinedInput
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={placeholder}
                    sx={{ backgroundColor: '#FFF', fontSize: '1rem', color: theme.palette.common.black, borderRadius: '4px' }}
                    id="outlined-basic"
                    startAdornment={<InputAdornment position="start"><SearchIcon sx={{ color: theme.palette.common.black }} />
                    </InputAdornment>}
                />
                <Box marginLeft={2} width={"10rem"} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                    <Button id='btn-search' onClick={() => handleSearch && handleSearch(searchQuery)} sx={{ width: "100%", height: "100%", borderRadius: "4px", }} variant="contained">Search</Button>
                </Box>
            </Box>

            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, height: '5ch' },
                    display: {
                        md: "none",
                        sm: "none",
                        xs: "flex",
                    },
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: '30ch',
                }}
                noValidate
                autoComplete="off"
            >
                <OutlinedInput
                    fullWidth
                    placeholder="Search"
                    sx={{ backgroundColor: '#FFF', fontSize: '1rem', color: theme.palette.common.black, borderRadius: '4px' }}
                    id="outlined-basic"
                    startAdornment={<InputAdornment position="start"><SearchIcon sx={{ color: theme.palette.common.black }} />
                    </InputAdornment>}
                />
                <Box marginLeft={2} width={"7rem"} height={"5ch"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Button sx={{ width: "100%", height: "100%", borderRadius: "4px", }} variant="contained">Search</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default SearchFeature