import React from 'react';
import { Snackbar, Alert } from '@mui/material';

interface AlertMessageProps {
    message: string;
    type: 'success' | 'error' | 'warning';
}

const AlertMessage: React.FC<AlertMessageProps> = ({ message, type }) => {
    return (
        <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  >
            <Alert severity={type} sx={{ width: '400px' }}>{message} </Alert>
        </Snackbar>
    );
};

export default AlertMessage;
